import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Avatar } from 'antd';
import { useCookies } from 'react-cookie';
import axiosInstance from '../../api/axiosInstance';
import { defaultsCover, defaultsImage, getTimeSinceDate } from '../../var_func/var_func';
import { Button } from 'react-bootstrap';
import { DeletePop } from '../../components/forms/PopForm';
import { HeaderProfile, PageHeader, PaginationPage, ButtonGroups } from '../../components/global';
import { userGroup, userType } from '../../components/accounts/Setings';
import { SortAccounts, SearchAccounts, EditFormAccounts, TableAccounts } from '../../components/accounts';
import { EditPasswordAccounts, EditStatusAccounts } from '../../components/accounts/Edit';
import { EditFormSurvey } from '../../components/survey/Edit';



export function ItemsSurvey({ type }) {
    const { id, name } = useParams()
    const [data, setData] = useState({
        item: '',
    })
    const [open, setOpen] = useState({
        one: false,
        two: false,
        three: false,
        password: false,
        status: false,
        delete: false,
        type: 777,
        id: 0,
        item: null
    });

    const [post, setPost] = useState({
        count: 0,
    })
    const drawer = (name, value, type) => {
        if (type && type !== 0) {
            setOpen((state) => {
                return { ...state, [name]: value, type: type }
            })
        } else {
            setOpen((state) => {
                return { ...state, [name]: value }
            })
        }
    }
    const getApiData = (name, value) => {
        setData((state) => {
            return { ...state, [name]: value }
        })
    }
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    const url = '/m3raaj/api/v1/survey/';
    const title = type === 1 ? 'رحلة أمرلي' : 'رحلة بيجي';
    const path = type === 1 ? '/emirli/list/' : '/baiji/list/';
    useEffect(() => {
        //get death

        async function getData() {
            await axiosInstance.get(`${url}${id}/`)
                .then(res => {
                    getApiData('item', { ...res.data.result })
                });

        };
        getData();
    }, [id, post.count]);
    const login = getTimeSinceDate(data.item.last_login);
    if (!data.item) return null;
    return (
        <div>
            <div class="container-fluid">
                <div class="row justify-content-center align-items-center g-2">
                    <div class="col-xl-12">
                        <div class="container-fluid">
                            <div class="row justify-content-center align-items-center g-2">
                                <div class="col-xl-12">
                                    <PageHeader
                                        title={`${data.item.name}`}
                                        links={[
                                            {
                                                name: title,
                                                path: '#'
                                            },
                                            {
                                                name: 'قائمة حجوزات ' + title,
                                                path: path
                                            }
                                        ]}
                                    />
                                </div>
                                <div class="col-xl-12">
                                    <ButtonGroups path='#' title='الرجوع' icon='fas fa-chevron-right' />
                                    <ButtonGroups path={path} title={'قائمة حجوزات ' + title} icon='fas fa-list-alt' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-12">
                        <div class="container-fluid man-content">
                            <div class="row justify-content-center align-items-start g-2">
                                <div class="col">
                                    <div className='man-link'>
                                        <a href='#الأساسية'>المعلومات الأساسية</a>
                                        <a href='#' onClick={() => drawer('three', true)}>تعديل  البيانات </a>
                                        <a href='#' className='delete-link' onClick={() => drawer('delete', true)}>حذف البيانات</a>
                                    </div>
                                </div>
                                <div class="col-lg-10 col-md-12 border-end">
                                    <div class="container-fluid">
                                        <div class="row justify-content-center align-items-start g-2">
                                            <div id='الأساسية' class="col-12 border-bottom">
                                                <p className='fs-22 f-bold pt-3'>المعلومات الأساسية</p>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <div className='d-flex'>
                                                        <div className='man-info-name'>
                                                            <p className='text-black-50 fs-12'>صاحب الحجز</p>
                                                            <p className='fs-20 f-bold'> {data.item.name} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>بيانات الحجز</p>
                                                    <div class="container">
                                                        <div class="row g-2">
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>الإسم</p>
                                                                <p className='fs-16 f-bold'> {data.item.name}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'> الرحلة</p>
                                                                <p className='fs-16 f-bold'> {data.item.trips}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>المحافظة</p>
                                                                <p className='fs-16 f-bold'> {data.item.city}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>الجنس</p>
                                                                <p className='fs-16 f-bold'> {data.item.sex}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>العمر</p>
                                                                <p className='fs-16 f-bold'> {data.item.age}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>التحصيل الدراسي</p>
                                                                <p className='fs-16 f-bold'> {data.item.education}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>رقم الهاتف</p>
                                                                <p className='fs-16 f-bold'> {data.item.phone}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>رابط الأستمارة</p>
                                                                <p className='fs-16 f-bold'> {data.item.survey_link}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>الرحلات السابقة</p>
                                                                <p className='fs-16 f-bold'> {data.item.previous_trips ? "نعم يوجد رحلة" : "لا يوجد رحلة"}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'> الأطفال</p>
                                                                <p className='fs-16 f-bold'> {data.item.has_children ? "نعم يوجد اطفال" : "لا يوجد اطفال"}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'> عدد الأطفال</p>
                                                                <p className='fs-16 f-bold'> {data.item.children_count ? data.item.children_count : "لا يوجد اطفال"}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'> أعمار الأطفال</p>
                                                                <p className='fs-16 f-bold'>{data.item.children_ages  ? (data.item.children_ages.map((i, n) => `عمر الطفل (${n + 1}) : ${i} سنوات ,`)) : "لا يوجد اطفال"}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'> حالة الرحلة</p>
                                                                <p className='fs-16 f-bold'> {data.item.is_active ? 'تم الحضور' : "لم يتم الحضور"}</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <SortAccounts
                    open={open.one}
                    onClose={() => drawer('one', false)}
                />
                <SearchAccounts
                    open={open.two}
                    onClose={() => drawer('two', false)}
                />
                <EditFormSurvey
                    id={id}
                    item={data.item}
                    type={type}
                    open={open.three}
                    onClose={() => drawer('three', false)}
                    onPut={() => changePost('count', post.count + 1)}
                />


                <DeletePop
                    type='item'
                    url={`${url}${id}/`}
                    path={path}
                    show={open.delete}
                    handleClose={() => drawer('delete', false)}
                />
            </div>
        </div>
    )
}
