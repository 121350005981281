import React from 'react'
import { Pie } from 'react-chartjs-2';
import Chart from "chart.js";
import {
    chartOptions,
    parseOptions,
    chartExample3
} from './chartOptions';

export default function PieChart({ labels, data }) {
    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }
    return (
        <div className='chart m-2'>
            <Pie
                data={chartExample3.data(labels, data)}
                options={{ maintainAspectRatio: false }}
                width={150}
                height={150}
            />
        </div>

    )
}