
import axios from 'axios'
import CryptoJS from 'crypto-js'

export const key_crypto = '+!9Z1Ke87tRl1*W?-deD-0aZItR2blk-vas@Ud715l61yu!R8gek!tr2RIdrI2r4';


const unreal_token = 'n7qespabrivI=atri1o#ecrOgastUyiy2truthoxe1h&fecheqeWew5swem3fo7h';
const baseURL = 'https://api.m3raaj.com/';
// const baseURL = 'http://139.162.152.250';
const accessToken = localStorage.getItem('access')
const AccessToken = !accessToken ? unreal_token : JSON.parse(CryptoJS.AES.decrypt(accessToken, key_crypto).toString(CryptoJS.enc.Utf8));
const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 30000,
    headers: {
        'Authorization': accessToken ? AccessToken : null,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
});
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        console.log(error.response.data.code)
        const originalRequest = error.config;
        // Prevent infinite loops
        if (error.request.status === 401 && originalRequest.url === baseURL + '/api/token/refresh/') {
            window.location.href = '/login';
            return Promise.reject(error);
        }
        if (error.response.data.code === "token_not_valid") {
            localStorage.removeItem('access')
            const refresh = localStorage.getItem('refresh');
            const refreshToken = JSON.parse(CryptoJS.AES.decrypt(refresh, key_crypto).toString(CryptoJS.enc.Utf8))
            if (refreshToken) {
                const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
                // exp date in token is expressed in seconds, while now() returns milliseconds:
                const now = Math.ceil(Date.now() / 1000);
                if (tokenParts.exp > now) {
                    return axiosInstance.post('/api/token/refresh/', { refresh: refreshToken })
                        .then((response) => {
                            const token = response.data.access
                            const access = CryptoJS.AES.encrypt(JSON.stringify(token), key_crypto).toString()
                            localStorage.setItem('access', access);
                            axiosInstance.defaults.headers['Authorization'] = token;
                            originalRequest.headers['Authorization'] = token;
                            return axiosInstance(originalRequest);
                        })
                        .catch(error => {
                            console.log(error)
                        });
                } else {
                    console.log("Refresh token is expired", tokenParts.exp, now);
                    return localStorage.clear()
                }
            } else {
                console.log("Refresh token not available.")
            }
        }
        // specific error handling done elsewhere
        return Promise.reject(error);
    }
);

export default axiosInstance
