import { Avatar, Drawer } from 'antd';
import React, { useState } from 'react'
import { CustomInput, CustomSelect } from '../forms/CustomForms';
import axiosInstance from '../../api/axiosInstance';
import { useEffect } from 'react';
import { Button, Offcanvas, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { defaultsImage, downlodXlsx } from '../../var_func/var_func';
import { useCookies } from 'react-cookie';
import { PaginationPage } from '../global';
import { ReportPop } from '../forms/PopForm';
import { customDataSurvey } from '../../pages/survey/data';
import moment from 'moment';

export function SortSurvey(prpos) {
    const { onClose, open, type } = prpos;
    const [page, setPage] = useCookies('');
    const { sort_survey_list } = page;
    const [sortValues, setSortValues] = useState({
        trips: '',
        season: '',
        sex: '',
        city: '',
        age: '',
        education: '',
        has_children: '',
        previous_trips: '',
        is_active: '',
        survey_link: '',

    })
    const [SortData, setSortData] = useState({
        managements: [],
        list: [],
        listReport: [],
        listCount: 0
    })
    const [formSearch, setFormSearch] = useState({
        managements: '',
    })
    const [pop, setPop] = useState({
        one: false,
        two: false,
        report: false,
    });
    const ondrawer = (name, value) => {
        setPop((state) => {
            return { ...state, [name]: value }
        })
    }
    const [drawer, setDrawer] = useState(false);
    const change = (name, value) => {
        setSortValues((state) => {
            return { ...state, [name]: value }
        })
    }
    const getFormData = (name, value) => {
        setSortData((state) => {
            return { ...state, [name]: value }
        })
    }
    const searchForm = (name, value) => {
        setFormSearch((state) => {
            return { ...state, [name]: value }
        })
    }
    const showChildrenDrawer = () => {
        setDrawer(true);
    };

    const onChildrenDrawerClose = () => {
        setDrawer(false);
    };
    const path = type === 1 ? '/emirli/list/' : '/baiji/list/';
    const url = type === 1 ? '/m3raaj/api/v1/survey/?type=0' : '/m3raaj/api/v1/survey/?type=1';
    const title = type === 1 ? 'رحلة أمرلي' : 'رحلة بيجي';
    useEffect(() => {
        //get death
        async function getData() {
            const sort = `season=${sortValues.season}&trips=${sortValues.trips}&is_active=${sortValues.is_active}&city=${sortValues.city}&age=${sortValues.age}&sex=${sortValues.sex}&survey_link=${sortValues.survey_link}&education=${sortValues.education}&has_children=${sortValues.has_children}&previous_trips=${sortValues.previous_trips}`;
            if (drawer) {
                await axiosInstance.get(`${url}&${sort}&page_size=100&page=${sort_survey_list ? sort_survey_list : 1}`)
                    .then(res => {
                        getFormData('list', res.data.results)
                        getFormData('listCount', res.data.count)
                        if (res.data.count > 0 && res.data.results.length === 0) setPage('sort_survey_list', 1)
                    }).catch(err => {
                        if (err.response.status === 304) {
                            setPage('sort_survey_list', 1)
                        }
                    })
            }
        }
        getData()
    }, [sortValues, drawer, sort_survey_list])
    useEffect(() => {
        //get death
        async function getData() {
            if (pop.report) {
                const sort = `season=${sortValues.season}&trips=${sortValues.trips}&is_active=${sortValues.is_active}&city=${sortValues.city}&age=${sortValues.age}&survey_link=${sortValues.survey_link}&education=${sortValues.education}&has_children=${sortValues.has_children}&previous_trips=${sortValues.previous_trips}`;
                if (drawer) {
                    await axiosInstance.get(`${url}&${sort}&page_size=${SortData.listCount}`)
                        .then(res => {
                            getFormData('listReport', res.data.results)
                        }).catch(err => {
                            ondrawer('report', false)
                        })
                }
            }
        }
        getData()
    }, [sortValues, drawer, sort_survey_list, pop.report])

    const city = ["أربيل", "الانبار", "بابل", "بغداد", "البصرة", "دهوك", "القادسية", "ديالى", "ذي قار", "السليمانية", "صلاح الدين", "كركوك", "كربلاء", "المثنى", "النجف", "نينوى", "واسط", "ميسان"]
    const part = [
        "18/10/2024 - الرحلة الاولى",
        "25/10/2024 - الرحلة الثانية",
        "01/11/2024 - الرحلة الثالثة",
        "08/11/2024 - الرحلة الرابعة",
        "15/11/2024 - الرحلة الخامسة",
        "22/11/2024 - الرحلة السادسة",
        "29/11/2024 - الرحلة السابعة",
        "06/12/2024 - الرحلة الثامنة"
    ]
    const part2 = [
        "16/02/2024 - الرحلة الاولى",
        "01/03/2024 - الرحلة الثانية",
        "08/03/2024 - الرحلة الثالثة",
        // "10/11 / 2023 - الرحلة الرابعة",
        // "17 /11/2023 - الرحلة الخامسة",
        // "24 / 11 / 2023 - الرحلة السادسة",
        // "01 / 12 / 2023 - الرحلة السابعة",
        // "08 / 12 / 2023 - الرحلة الثامنة"
    ]
    const edu = ["دكتوراه", "ماجستير", "بكالوريوس", "دبلوم", "اعدادية", "متوسطة", "ابتدائية", "يقرأ ويكتب"]
    const link = ["الأصدقاء", "فيسبوك", "انستكرام", "تلجرام"]
    const seasons = [
        { key: 3, name: 'الثالث' },
        { key: 4, name: 'الرابع' },
        { key: 5, name: 'الخامس' },
    ]
    const partByType = type === 1 ? part : part2
    const onButtonReport = () => {
        downlodXlsx(customDataSurvey(SortData.listReport), 'تقرير حجوزات ' + title + Math.floor(Math.random() * 100000))
    }

    return (
        <>
            <Offcanvas show={open} onHide={onClose} className='offcanvas-edit'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>فرز {title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div class="container-fluid">
                        <div className='row justify-content-center g-2'>
                            <div className='col-12'>
                                <label>الموسم</label>
                                <CustomSelect
                                    icon='date_range'
                                    name='season'
                                    onChange={(e) => change('season', e === undefined ? '' : e)}
                                    onClear={() => change('season', '')}
                                >
                                    {seasons.map(item => <option value={item.key}>{item.name}</option>)}
                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>الرحلة</label>
                                <CustomSelect
                                    icon='date_range'
                                    name='trips'
                                    onChange={(e) => change('trips', e === undefined ? '' : e)}
                                    onClear={() => change('trips', '')}
                                >
                                    {partByType.map(item => <option value={item}>{item}</option>)}
                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>المحافظة</label>
                                <CustomSelect
                                    icon='location_city'
                                    name='city'
                                    onChange={(e) => change('city', e === undefined ? '' : e)}
                                    onClear={() => change('city', '')}
                                >
                                    {city.map(item => <option value={item}>{item}</option>)}
                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label className='optional'>العمر</label>
                                <CustomInput type="number" icon='123' name='age' min={0} max={150} placeholder="العمر" onChange={(e) => change(e.target.name, e.target.value)} required />
                            </div>
                            <div className='col-12'>
                                <label>الجنس</label>
                                <CustomSelect
                                    icon='wc'
                                    name='sex'
                                    onChange={(e) => change('sex', e === undefined ? '' : e)}
                                    onClear={() => change('sex', '')}
                                >
                                    <option value='ذكر'>ذكر</option>
                                    <option value='انثى'>انثى</option>

                                </CustomSelect>
                            </div>

                            <div className='col-12'>
                                <label>التحصيل الدراسي</label>
                                <CustomSelect
                                    icon='school'
                                    name='education'
                                    onChange={(e) => change('education', e === undefined ? '' : e)}
                                    onClear={() => change('education', '')}
                                >
                                    {edu.map(item => <option value={item}>{item}</option>)}
                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>رابط الاستمارة</label>
                                <CustomSelect
                                    icon='link'
                                    name='has_children'
                                    onChange={(e) => change('survey_link', e === undefined ? '' : e)}
                                    onClear={() => change('survey_link', '')}
                                >
                                    {link.map(item => <option value={item}>{item}</option>)}
                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>أصطحاب الأطفال</label>
                                <CustomSelect
                                    icon='escalator_warning'
                                    name='has_children'
                                    onChange={(e) => change('has_children', e === undefined ? '' : e)}
                                    onClear={() => change('has_children', '')}
                                >
                                    <option value={true}>نعم</option>
                                    <option value={false}>كلا</option>

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>المشاركة في رحلات سابقة</label>
                                <CustomSelect
                                    icon='airport_shuttle'
                                    name='previous_trips'
                                    onChange={(e) => change('previous_trips', e === undefined ? '' : e)}
                                    onClear={() => change('previous_trips', '')}
                                >
                                    <option value={true}>نعم</option>
                                    <option value={false}>كلا</option>

                                </CustomSelect>
                            </div>

                            <div className='col-12'>
                                <label className='optional'>حالة الرحلة</label>
                                <CustomSelect
                                    icon='done'
                                    name='is_active'
                                    onChange={(e) => change('is_active', e === undefined ? '' : e)}
                                    onClear={() => change('is_active', '')}
                                >
                                    <option value={true}>تم الحضور</option>
                                    <option value={false}>لم يتم الحضور</option>

                                </CustomSelect>
                            </div>
                        </div>
                        <div className='row justify-content-center g-2 mt-5 '>
                            <Button variant="" className='but-all w-100' onClick={showChildrenDrawer}>
                                معاينة النتائج
                            </Button>
                        </div>
                    </div>
                    <Offcanvas show={drawer} onHide={onChildrenDrawerClose} className='offcanvas-edit'>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>نتائج الفرز</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            {SortData.listCount > 0 ?
                                <>
                                    <p>عدد النتائج : {SortData.listCount}</p>
                                    <div className='table-responsive'>
                                        <Table className="align-items-center table-flush">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>ت</th>
                                                    <th scope="col">الأسم</th>
                                                    <th scope="col">الرحلة</th>
                                                    <th scope="col">المحافظة</th>
                                                    <th scope="col">الجنس</th>
                                                    <th scope="col">العمر</th>
                                                    <th scope="col">التحصيل الدراسي</th>
                                                    <th scope="col">رقم الهاتف</th>
                                                    <th scope="col">رابط الاستمارة</th>
                                                    <th scope="col"> الرحلات السابقة</th>
                                                    <th scope="col">الأطفال</th>
                                                    <th scope="col">عدد الأطفال</th>
                                                    <th scope="col">تاريخ التقديم</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {SortData.list.map((item, index) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{(((sort_survey_list ? sort_survey_list : 1) - 1) * 100) + (index + 1)}</td>
                                                            <th scope="row">
                                                                <Link to={`${path}item/id/${item.id}/name/${item.name}`}>
                                                                    {item.name}
                                                                </Link>
                                                            </th>
                                                            <td>{item.trips}</td>
                                                            <td>{item.city}</td>
                                                            <td>{item.sex}</td>
                                                            <td>{item.age}</td>
                                                            <td>{item.education}</td>
                                                            <td>{item.phone}</td>
                                                            <td>{item.survey_link}</td>
                                                            <td>{item.previous_trips ? "نعم يوجد رحلة" : "لا يوجد رحلة"}</td>
                                                            <td>{item.has_children ? "نعم يوجد اطفال" : "لا يوجد اطفال"}</td>
                                                            <td>{item.children_count ? item.children_count : "لا يوجد اطفال"}</td>
                                                            <td dir='ltr'>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </>
                                :
                                <div className='d-flex flex-column justify-content-center align-items-center py-5'>
                                    <i class="fa-solid fa-table" style={{ fontSize: 75, color: '#aaaa' }}></i>
                                    <p className='py-2' style={{ fontSize: 25, color: '#aaaa' }}>لا توجد بيانات</p>
                                </div>
                            }


                            <div className="col-12">
                                <PaginationPage
                                    defaultCurrent={!sort_survey_list ? 1 : Number(sort_survey_list)}
                                    total={SortData.listCount}
                                    defaultPageSize={100}
                                    onChange={(e) => setPage('sort_survey_list', e === undefined ? '' : e)}
                                />
                            </div>
                            {SortData.listCount > 0 &&
                                <div className='row justify-content-center g-2 mt-5 '>
                                    <Button variant="" className='but-all w-100' onClick={() => ondrawer('report', true)}>
                                        تحميل البيانات
                                    </Button>
                                </div>
                            }
                        </Offcanvas.Body>
                    </Offcanvas>
                </Offcanvas.Body>
            </Offcanvas>
            <ReportPop
                open={pop.report}
                title={title}
                onClose={() => ondrawer('report', false)}
                onOK={onButtonReport}
            />
        </>
    )
}


