import React, { useContext, useState } from 'react'
import { AuthApi } from '../Context';
import axiosInstance, { key_crypto } from '../api/axiosInstance';
import CryptoJS from 'crypto-js'
import { Alert, Input } from 'antd';

export default function Login({ setloading }) {
    const [errn, setErrn] = useState(false)
    const [errnM, setErrnM] = useState(false)
    const Auth = useContext(AuthApi)
    const [user, setUser] = useState({});
    const handleChange = e => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        await axiosInstance.post("/api/token/", user).then(data => {
            const { access, refresh, type, id, group_type } = data.data;
            const Access = CryptoJS.AES.encrypt(JSON.stringify(access), key_crypto).toString()
            const Refresh = CryptoJS.AES.encrypt(JSON.stringify(refresh), key_crypto).toString()
            const username = CryptoJS.AES.encrypt(JSON.stringify(user.username), key_crypto).toString()
            const encryptType = CryptoJS.AES.encrypt(JSON.stringify(type), key_crypto).toString()
            const encryptid = CryptoJS.AES.encrypt(JSON.stringify(id), key_crypto).toString()
            const encryptgroup = CryptoJS.AES.encrypt(JSON.stringify(group_type), key_crypto).toString()
            localStorage.setItem("access", Access);
            localStorage.setItem("refresh", Refresh);
            localStorage.setItem('user', username)
            localStorage.setItem('name_type', encryptType)
            localStorage.setItem('group_type', encryptgroup)
            localStorage.setItem('user_id', encryptid)
        }).then((res) => {
            setloading(true)
            setTimeout(() => Auth.setAuth(true), 8000);
            setTimeout(() => window.location.reload(), 8001);
        })
            .catch(error => {
                console.log(error.response.status)
                if (error.message === 'Network Error') {
                    setErrnM('')
                    setErrn(true)
                } else if (error.response.status === 401) {
                    setErrn(true)
                    setErrnM('كلمة السر أو إسم المستخدم خطأ')
                } else if (error.response.status === 403) {
                    setErrn(true)
                    setErrnM('الحساب معطل من قبل الأدارة')
                }
            })
    };
    return (
        <>
            {errn && <Alert message={errnM} afterClose={() => setErrn(false)} type="error" showIcon closable />}
            <div class="container-fluid container-fluid-form m-0 p-0 w-100">
                <div class="row justify-content-center g-2">
                    <div class="col-xl-5 col-lg-6 col-sm-12  login-form-containe d-flex flex-column justify-content-center align-items-center">
                        <img src={require('../styles/images/dashboard_img/logo.png')} className='login-logo-form mt-5' />
                        <div class="container-fluid">
                            <div class="row justify-content-center align-items-center g-2">
                                <div class="col-lg-10 color-form-login my-5">
                                    <p className="title-login-form">تسجيل الدخول لمديري مشروع معراج</p>
                                    <form className="login-form" onChange={handleChange} onSubmit={handleSubmit} >
                                        <div class="row justify-content-center align-items-center g-2">
                                            <div className="form-group col-md-12">
                                                <label for="exampleInputEmail1" className="text-uppercase" >إسم المستخدم</label>
                                                <div className="input-group">
                                                    <Input type="text" size='large' name='username' prefix={<i class="fas fa-user ms-1 text-black-50"></i>} placeholder="اسم المستخدم" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center align-items-center g-2">
                                            <div className="form-group col-md-12">
                                                <label for="exampleInputPassword1" className="text-uppercase">كلمة المرور</label>
                                                <div className="input-group">
                                                    <Input.Password size='large' type="password" prefix={<i class="fas fa-key ms-1 text-black-50"></i>} name='password' placeholder="كلمة المرور" id="inputPassword" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center align-items-center g-2 mt-2">
                                            <div className="form-check col-md-6">
                                                <input type='submit' className="form-control bott" value='تسجيل الدخول' />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-xl-7 col-lg-6 col-sm-12 login-color-container carve">
                        <div className='login-color-container__bg'></div>
                        <div className='col-12 footer-login-form '>
                            <p className='m-2'> هيئة الحشد الشعبي - المديرية العامة للعلاقات والخدمات الأجتماعية 2023-2024 </p>
                        </div>
                    </div>
             
                </div>
            </div>

        </>
    )
}
