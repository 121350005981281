import { Checkbox, Radio } from 'antd'
import React, { useEffect, useState } from 'react'
import axiosInstance from '../api/axiosInstance'
import { Button } from 'react-bootstrap'
import { PageHeader } from '../components/global'
import { AddPop, ErrorPop } from '../components/forms/PopForm'
export default function Settings() {
    const [reviews, setReviews] = useState({
        key: 'reviews',
        value: ''
    })
    const [survey, setSurvey] = useState({
        key: 'survey',
        value: ''
    })
    const [emirli, setEmirli] = useState({
        key: 'emirli',
        value: ''
    })
    const [baiji, setBaiji] = useState({
        key: 'baiji',
        value: ''
    })
    const [trips, setTrips] = useState([]);
    const [trip, setTrip] = useState([
        {
            name: "الرحلة الاولى",
            is_active: true
        },
        {
            name: "الرحلة الثانية",
            is_active: true
        },
        {
            name: "الرحلة الثالثة",
            is_active: true
        },
        {
            name: "الرحلة الرابعة",
            is_active: true
        },
        {
            name: "الرحلة الخامسة",
            is_active: true
        },
        {
            name: "الرحلة السادسة",
            is_active: true
        },
        {
            name: "الرحلة السابعة",
            is_active: true
        },
        {
            name: "الرحلة الثامنة",
            is_active: true
        },
    ])
    const [tripreviews, setTripreviews] = useState([
        {
            name: "الرحلة الاولى",
            is_active: true
        },
        {
            name: "الرحلة الثانية",
            is_active: true
        },
        {
            name: "الرحلة الثالثة",
            is_active: true
        },
        {
            name: "الرحلة الرابعة",
            is_active: true
        },
        {
            name: "الرحلة الخامسة",
            is_active: true
        },
        {
            name: "الرحلة السادسة",
            is_active: true
        },
        {
            name: "الرحلة السابعة",
            is_active: true
        },
        {
            name: "الرحلة الثامنة",
            is_active: true
        },
    ])
    const handleFieldValueChange = (e, index) => {
        const newFieldValues = [...trip];
        newFieldValues[index].is_active = e.target.value;
        setTrip([...newFieldValues])
    };
    const handleFieldValueChangereviews = (e, index) => {
        const newFieldValues = [...tripreviews];
        newFieldValues[index].is_active = e.target.value;
        setTripreviews([...newFieldValues])
    };
    console.log(trip)
    const changeSurvey = (value) => {
        setSurvey((state) => {
            return { ...state, value: value }
        })
    }
    const popForm = (name, value) => {
        setFormPop((state) => {
            return { ...state, [name]: value }
        })
    }
    const [formPop, setFormPop] = useState({
        showOne: false,
        showTwo: false,
        showError: false,
        errorMessage: '',
    })
    const [post, setPost] = useState({
        progress: 0,
        count: 0,
    })
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    const changeEmirli = (value) => {
        setEmirli((state) => {
            return { ...state, value: value }
        })
    }
    const changeBaiji = (value) => {
        setBaiji((state) => {
            return { ...state, value: value }
        })
    }
    const changeReviews = (value) => {
        setReviews((state) => {
            return { ...state, value: value }
        })
    }
    const onPost = async (data, reload) => {
        const UploadProgress = (event) => {
            const { loaded, total } = event;
            let percent = Math.floor((loaded * 100) / total)
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            changePost('progress', percent)
        }
        const option = { onUploadProgress: UploadProgress }
        await axiosInstance.post('/setting/api/v1/settings/', data, option)
            .catch(error => {
                popForm('errorMessage', 'حدث خطأ ما يرجى التأكد من الأتصال')
            }).then(res => {
                if (reload) {
                    if (res.status === 201) {
                        console.log('reload')
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000);
                    }
                } else {
                    console.log('not reload')
                }

            })
    }
    const onPostTrip = async (reload) => {
        const UploadProgress = (event) => {
            const { loaded, total } = event;
            let percent = Math.floor((loaded * 100) / total)
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            changePost('progress', percent)
        }

        let data = {
            key: 'trips',
            value: JSON.stringify(trip.map((item, index) => ({ [index]: item.is_active })))
        }
        const option = { onUploadProgress: UploadProgress }
        await axiosInstance.post('/setting/api/v1/settings/', data, option)
            .catch(error => {
                popForm('errorMessage', 'حدث خطأ ما يرجى التأكد من الأتصال')
            }).then(res => {
                if (reload) {
                    if (res.status === 201) {
                        console.log('reload')
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000);
                    }
                } else {
                    console.log('not reload')
                }

            })
    }
    const onPostTripreviews = async (reload) => {
        const UploadProgress = (event) => {
            const { loaded, total } = event;
            let percent = Math.floor((loaded * 100) / total)
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            changePost('progress', percent)
        }

        let data = {
            key: 'reviews_trips',
            value: JSON.stringify(tripreviews.map((item, index) => ({ [index]: item.is_active })))
        }
        const option = { onUploadProgress: UploadProgress }
        await axiosInstance.post('/setting/api/v1/settings/', data, option)
            .catch(error => {
                popForm('errorMessage', 'حدث خطأ ما يرجى التأكد من الأتصال')
            }).then(res => {
                if (reload) {
                    if (res.status === 201) {
                        console.log('reload')
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000);
                    }
                } else {
                    console.log('not reload')
                }

            })
    }
    const onSettings = (e) => {
        e.preventDefault()
        if (survey.value) onPost(survey, false)
        if (emirli.value) onPost(emirli, false)
        if (reviews.value) onPost(reviews, false)
        onPostTrip(false)
        onPostTripreviews(false)
        if (baiji.value) onPost(baiji, true)
    }
    useEffect(() => {
        async function getData() {
            await axiosInstance.get(`/setting/api/v1/settings/find/?key=survey`)
                .then(res => {
                    changeSurvey(res.data.value)
                })
            await axiosInstance.get(`/setting/api/v1/settings/find/?key=emirli`)
                .then(res => {
                    changeEmirli(res.data.value)
                })
            await axiosInstance.get(`/setting/api/v1/settings/find/?key=baiji`)
                .then(res => {
                    changeBaiji(res.data.value)
                })
            await axiosInstance.get(`/setting/api/v1/settings/find/?key=reviews`)
                .then(res => {
                    changeReviews(res.data.value)
                })
            await axiosInstance.get(`/setting/api/v1/settings/find/?key=trips`)
                .then(res => {
                    const data = res.data.value
                    if (data) {
                        const dataParse = JSON.parse(data)
                        const newFieldValues = [...trip];

                        dataParse.map((i, index) => {
                            newFieldValues[index].is_active = i[index];
                        })
                        setTrip([...newFieldValues])

                    }
                })
            await axiosInstance.get(`/setting/api/v1/settings/find/?key=reviews_trips`)
                .then(res => {
                    const data = res.data.value
                    if (data) {
                        const dataParse = JSON.parse(data)
                        const newFieldValues = [...tripreviews];

                        dataParse.map((i, index) => {
                            newFieldValues[index].is_active = i[index];
                        })
                        setTripreviews([...newFieldValues])

                    }
                })
        }
        getData()
    }, [])

    return (
        <div className='m-3'>
            <div class="col-xl-12 m-2">
                <PageHeader
                    title={`الأعدادات العامة`}
                    links={[]}
                />
            </div>
            <div class="container-fluid settings">
                <div class="row justify-content-center align-items-center g-2">

                    <div class="col-12 d-flex justify-content-between p-3 border-bottom">
                        <h5 className='f-bold'><i class="fa-solid fa-gears" style={{ color: '#0c7676' }}></i> الأعدادات العامة</h5>
                        <Button
                            type='button'
                            variant=''
                            onClick={() => popForm('showOne', true)}
                            className='but-all'
                        >
                            حفظ الأعدادات
                        </Button>
                    </div>
                    <div class="col-12 pb-5">
                        <h3 className='settings-title'>الأستمارات</h3>
                        <p className='settings-info'>أيقاف الأستمارات بسكل عام اي لن تظهر اي معلومة عن الأستمارات في الموقع الألكتروني</p>
                        <Radio.Group onChange={(e) => changeSurvey(e.target.value)} value={survey.value}>
                            <Radio value={"true"}>تفعيل</Radio>
                            <Radio value={"false"}>ايقاف</Radio>
                        </Radio.Group>
                    </div>
                    <hr />
                    <div class="col-12 pb-5">
                        <h3 className='settings-title'>أستمارة أمرلي</h3>
                        <p className='settings-info'>أيقاف أستمارة أمرلي فقط بسكل عام اي لن تظهر اي معلومة عن أستمارة في الموقع الألكتروني </p>
                        <Radio.Group onChange={(e) => changeEmirli(e.target.value)} value={emirli.value}>
                            <Radio value={"true"}>تفعيل</Radio>
                            <Radio value={"false"}>ايقاف</Radio>
                        </Radio.Group>
                    </div>

                    <hr />
                    <div class="col-12 pb-5">
                        <h3 className='settings-title'>أستمارة بيجي</h3>
                        <p className='settings-info'>أيقاف أستمارة بيجي فقط بسكل عام اي لن تظهر اي معلومة عن أستمارة في الموقع الألكتروني </p>
                        <Radio.Group onChange={(e) => changeBaiji(e.target.value)} value={baiji.value}>
                            <Radio value={"true"}>تفعيل</Radio>
                            <Radio value={"false"}>ايقاف</Radio>
                        </Radio.Group>
                    </div>
                    <hr />
                    <div class="col-12 pb-5">
                        <h3 className='settings-title'>رحلات أمرلي</h3>
                        <p className='settings-info'>أيقاف الرحلات فقط اي لن تظهر اي معلومة عن الرحلات في الموقع الألكتروني</p>
                        {/* <Checkbox defaultChecked={baijiValue === 'true' ? true : false} onChange={(e) => changeBaiji(String(e.target.checked))}>تفعيل أستمارة بيجي</Checkbox> */}
                        <div class="container-fluid">
                            <div class="row justify-content-center align-items-center g-2">
                                {trip.map((item, index) => {
                                    return (
                                        <div key={index + 1} class="col-lg-3 col-md-6 mb-3">
                                            <p>{item.name}</p>
                                            <Radio.Group onChange={(e) => handleFieldValueChange(e, index)} value={trip[index].is_active}>
                                                <Radio value={true}>تفعيل</Radio>
                                                <Radio value={false}>ايقاف</Radio>
                                            </Radio.Group>
                                        </div>
                                    )
                                })}


                            </div>
                        </div>

                    </div>
                    <hr />
                    <div class="col-12 pb-5">
                        <h3 className='settings-title'>أستمارة التقييم</h3>
                        <p className='settings-info'>أيقاف أستمارة التقييم فقط بسكل عام اي لن تظهر اي معلومة عن أستمارة في الموقع الألكتروني </p>
                        <Radio.Group onChange={(e) => changeReviews(e.target.value)} value={reviews.value}>
                            <Radio value={"true"}>تفعيل</Radio>
                            <Radio value={"false"}>ايقاف</Radio>
                        </Radio.Group>
                    </div>
                    <hr />
                    <div class="col-12 pb-5">
                        <h3 className='settings-title'>رحلات التقييم</h3>
                        <p className='settings-info'>أيقاف الرحلات فقط اي لن تظهر اي معلومة عن الرحلات في استمارة التقييم في الموقع الألكتروني</p>
                        <div class="container-fluid">
                            <div class="row justify-content-center align-items-center g-2">
                                {tripreviews.map((item, index) => {
                                    return (
                                        <div key={index + 1} class="col-lg-3 col-md-6 mb-3">
                                            <p>{item.name}</p>
                                            <Radio.Group onChange={(e) => handleFieldValueChangereviews(e, index)} value={tripreviews[index].is_active}>
                                                <Radio value={true}>تفعيل</Radio>
                                                <Radio value={false}>ايقاف</Radio>
                                            </Radio.Group>
                                        </div>
                                    )
                                })}


                            </div>
                        </div>

                    </div>
                    <AddPop
                        showOne={formPop.showOne}
                        showTwo={formPop.showTwo}
                        handleshow={(e) => {
                            popForm('showOne', false)
                            onSettings(e)
                            popForm('showTwo', true)
                        }}
                        handleCloseOne={() => popForm('showOne', false)}
                        handleCloseTwo={() => popForm('showTwo', false)}
                        post={post.progress}
                    />
                    <ErrorPop
                        showError={formPop.showError}
                        handleCloseError={() => popForm('showError', false)}
                        errorMessage={formPop.errorMessage}
                    />
                </div>
            </div>
        </div>
    )
}
