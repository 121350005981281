import { Avatar } from 'antd';
import React, { useState } from 'react'
import { CustomInput } from '../forms/CustomForms';
import axiosInstance from '../../api/axiosInstance';
import { useEffect } from 'react';
import { Button, Offcanvas, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { defaultsImage } from '../../var_func/var_func';
import { useCookies } from 'react-cookie';
import { PaginationPage } from '../global';
import moment from 'moment';
export function SearchSurvey(prpos) {
    const { onClose, open, type } = prpos;
    const [page, setPage] = useCookies('');
    const { search_survey_list } = page;
    const [sortValues, setSortValues] = useState({
        name: '',
    })
    const [SortData, setSortData] = useState({
        list: [],
        listCount: 0
    })

    const [drawer, setDrawer] = useState(false);
    const change = (name, value) => {
        setSortValues((state) => {
            return { ...state, [name]: value }
        })
    }
    const getFormData = (name, value) => {
        setSortData((state) => {
            return { ...state, [name]: value }
        })
    }
    const showChildrenDrawer = () => {
        setDrawer(true);
    };

    const onChildrenDrawerClose = () => {
        setDrawer(false);
    };
    const path = type === 1 ? '/emirli/list/' : '/baiji/list/';
    const url = type === 1 ? '/m3raaj/api/v1/survey/?type=0' : '/m3raaj/api/v1/survey/?type=1';
    const title = type === 1 ? 'رحلة أمرلي' : 'رحلة بيجي';
    useEffect(() => {
        //get death
        async function getData() {
            const search = `${sortValues.name}`;
            if (drawer) {
                await axiosInstance.get(`${url}&search=${search}&page_size=100&page=${search_survey_list ? search_survey_list : 1}`)
                    .then(res => {
                        getFormData('list', res.data.results)
                        getFormData('listCount', res.data.count)
                        if (res.data.count > 0 && res.data.results.length === 0) setPage('sort_survey_list', 1)
                    }).catch(err => {
                        if (err.response.data.detail === 'Invalid page.') {
                            setPage('search_survey_list', 1)
                        }
                    })
            }

        }
        getData()
    }, [sortValues, drawer, search_survey_list])
    return (
        <Offcanvas show={open} onHide={onClose} className='offcanvas-edit'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>البحث قي قائمة {title}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div class="container-fluid">
                    <div className='row justify-content-center g-2'>
                        <div className='col-12' >
                            <label>بحث</label>
                            <CustomInput icon='search' name='name' onChange={(e) => change(e.target.name, e.target.value)} />
                        </div>

                    </div>
                    <div className='row justify-content-center g-2 mt-3'>
                        <Button variant="" className='but-all w-100' onClick={showChildrenDrawer}>
                            معاينة النتائج
                        </Button>
                    </div>
                </div>
                <Offcanvas show={drawer} onHide={onChildrenDrawerClose} className='offcanvas-edit'>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>نتائج البحث</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {SortData.listCount > 0 ?
                            <>
                                <p>عدد النتائج : {SortData.listCount}</p>
                                <div className='table-responsive'>
                                    <Table className="align-items-center table-flush">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>ت</th>
                                                <th scope="col">الأسم</th>
                                                <th scope="col">الرحلة</th>
                                                <th scope="col">المحافظة</th>
                                                <th scope="col">الجنس</th>
                                                <th scope="col">العمر</th>
                                                <th scope="col">التحصيل الدراسي</th>
                                                <th scope="col">رقم الهاتف</th>
                                                <th scope="col">رابط الاستمارة</th>
                                                <th scope="col"> الرحلات السابقة</th>
                                                <th scope="col">الأطفال</th>
                                                <th scope="col">عدد الأطفال</th>
                                                <th scope="col">تاريخ التقديم</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {SortData.list.map((item, index) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{(((search_survey_list ? search_survey_list : 1) - 1) * 100) + (index + 1)}</td>
                                                        <th scope="row">
                                                            <Link to={`${path}item/id/${item.id}/name/${item.name}`}>
                                                                {item.name}
                                                            </Link>
                                                        </th>
                                                        <td>{item.trips}</td>
                                                        <td>{item.city}</td>
                                                        <td>{item.sex}</td>
                                                        <td>{item.age}</td>
                                                        <td>{item.education}</td>
                                                        <td>{item.phone}</td>
                                                        <td>{item.survey_link}</td>
                                                        <td>{item.previous_trips ? "نعم يوجد رحلة" : "لا يوجد رحلة"}</td>
                                                        <td>{item.has_children ? "نعم يوجد اطفال" : "لا يوجد اطفال"}</td>
                                                        <td>{item.children_count ? item.children_count : "لا يوجد اطفال"}</td>
                                                        <td dir='ltr'>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </>
                            :
                            <div className='d-flex flex-column justify-content-center align-items-center py-5'>
                                <i class="fa-solid fa-table" style={{ fontSize: 75, color: '#aaaa' }}></i>
                                <p className='py-2' style={{ fontSize: 25, color: '#aaaa' }}>لا توجد بيانات</p>
                            </div>
                        }
                        <div className="col-12">
                            <PaginationPage
                                defaultCurrent={!search_survey_list ? 1 : Number(search_survey_list)}
                                total={SortData.martyrCount}
                                defaultPageSize={100}
                                onChange={(e) => setPage('search_survey_list', e === undefined ? '' : e)}
                            />
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </Offcanvas.Body>
        </Offcanvas>
    )
}


