import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Avatar } from 'antd';
import { useCookies } from 'react-cookie';
import axiosInstance from '../../api/axiosInstance';
import { defaultsCover, defaultsImage, getTimeSinceDate } from '../../var_func/var_func';
import { DeletePop } from '../../components/forms/PopForm';
import { PageHeader, ButtonGroups } from '../../components/global';



export function ItemsReviews({ type }) {
    const { id, name } = useParams()
    const [data, setData] = useState({
        item: '',
    })
    const [open, setOpen] = useState({
        one: false,
        two: false,
        three: false,
        password: false,
        status: false,
        delete: false,
        type: 777,
        id: 0,
        item: null
    });

    const [post, setPost] = useState({
        count: 0,
    })
    const drawer = (name, value, type) => {
        if (type && type !== 0) {
            setOpen((state) => {
                return { ...state, [name]: value, type: type }
            })
        } else {
            setOpen((state) => {
                return { ...state, [name]: value }
            })
        }
    }
    const getApiData = (name, value) => {
        setData((state) => {
            return { ...state, [name]: value }
        })
    }
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    const url = '/reviews/api/v1/survey/';
    const title = 'التقييمات';
    const path = '/reviews/list/';
    useEffect(() => {
        //get death

        async function getData() {
            await axiosInstance.get(`${url}${id}/`)
                .then(res => {
                    getApiData('item', { ...res.data.result })
                });

        };
        getData();
    }, [id, post.count]);
    const login = getTimeSinceDate(data.item.last_login);
    if (!data.item) return null;
    return (
        <div>
            <div class="container-fluid">
                <div class="row justify-content-center align-items-center g-2">
                    <div class="col-xl-12">
                        <div class="container-fluid">
                            <div class="row justify-content-center align-items-center g-2">
                                <div class="col-xl-12">
                                    <PageHeader
                                        title={`${data.item.trips}`}
                                        links={[
                                            {
                                                name: title,
                                                path: '#'
                                            },
                                            {
                                                name: 'قائمة ' + title,
                                                path: path
                                            }
                                        ]}
                                    />
                                </div>
                                <div class="col-xl-12">
                                    <ButtonGroups path='#' title='الرجوع' icon='fas fa-chevron-right' />
                                    <ButtonGroups path={path} title={'قائمة ' + title} icon='fas fa-list-alt' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-12">
                        <div class="container-fluid man-content">
                            <div class="row justify-content-center align-items-start g-2">
                                <div class="col-md-12">
                                    <div class="container-fluid">
                                        <div class="row justify-content-center align-items-start g-2">
                                            <div id='الأساسية' class="col-12 border-bottom">
                                                <p className='fs-22 f-bold pt-3'>المعلومات الأساسية</p>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <div className='d-flex'>
                                                        <div className='man-info-name'>
                                                            <p className='text-black-50 fs-12'>تقييم الرحلة</p>
                                                            <p className='fs-20 f-bold'> {data.item.trips} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>البيانات الأساسية</p>
                                                    <div class="container">
                                                        <div class="row g-2">
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>الرحلة</p>
                                                                <p className='fs-16 f-bold'> {data.item.trips}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'> رقم الباص</p>
                                                                <p className='fs-16 f-bold'> {data.item.bus}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>الجنس </p>
                                                                <p className='fs-16 f-bold'> {data.item.sex}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'> العمر</p>
                                                                <p className='fs-16 f-bold'> {data.item.age}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>المجموعات الخاصة بالمشتركين</p>
                                                                <p className='fs-16 f-bold'> {data.item.group_add ? "نعم" : "كلا"}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'> رحلات الموسم الاول</p>
                                                                <p className='fs-16 f-bold'> {data.item.first_season}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'> رحلات الموسم الثاني</p>
                                                                <p className='fs-16 f-bold'> {data.item.second_season}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>طالب جامعي</p>
                                                                <p className='fs-16 f-bold'> {data.item.college_student ? "نعم" : "كلا"}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>عضوية موكب أو هيئة حسينية</p>
                                                                <p className='fs-16 f-bold'> {data.item.parade_member ? "نعم" : "كلا"}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>الاستمارة الألكترونية</p>
                                                                <p className='fs-16 f-bold'> {data.item.form_fill ? "نعم" : "كلا"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>بيانات التقييم</p>
                                                    <div class="container">
                                                        <div class="row g-2">
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>سلاسة التسجيل في الرحلة</p>
                                                                <p className='fs-16 f-bold'> {data.item.registration_form}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>مواعيد الاستعداد والالتحاق بسيارات النقل</p>
                                                                <p className='fs-16 f-bold'> {data.item.appointments}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>الراحة في سيارات النقل</p>
                                                                <p className='fs-16 f-bold'> {data.item.comfort}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>وضوح إرشادات الكادر في السيارات</p>
                                                                <p className='fs-16 f-bold'> {data.item.instructions}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>عمل المتطوعين</p>
                                                                <p className='fs-16 f-bold'> {data.item.volunteers}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>المحطة الاولى</p>
                                                                <p className='fs-16 f-bold'> {data.item.first_station}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>المحطة الثانية</p>
                                                                <p className='fs-16 f-bold'> {data.item.second_station}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>المحطة الثالثة</p>
                                                                <p className='fs-16 f-bold'> {data.item.third_station}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>المحطة الرابعة</p>
                                                                <p className='fs-16 f-bold'> {data.item.fourth_station}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>المحطة الخامسة</p>
                                                                <p className='fs-16 f-bold'> {data.item.fifth_station}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>المحطة السادسة</p>
                                                                <p className='fs-16 f-bold'> {data.item.seventh_station}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>وضوح الرواة بالطرح والكلام</p>
                                                                <p className='fs-16 f-bold'> {data.item.speech}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>جودة وجبات الطعام</p>
                                                                <p className='fs-16 f-bold'> {data.item.food}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>راحة مكان المبيت</p>
                                                                <p className='fs-16 f-bold'> {data.item.overnight}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>جودة الإضاءة</p>
                                                                <p className='fs-16 f-bold'> {data.item.lighting}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>جودة الصوتيات</p>
                                                                <p className='fs-16 f-bold'> {data.item.acoustics}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>جودة تعامل الكادر</p>
                                                                <p className='fs-16 f-bold'> {data.item.team}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>منصة معراج على الفيسبوك</p>
                                                                <p className='fs-16 f-bold'> {data.item.facebook}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>منصة معراج على الانستكرام</p>
                                                                <p className='fs-16 f-bold'> {data.item.instagram}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>منصة معراج على التلكرام</p>
                                                                <p className='fs-16 f-bold'> {data.item.telegram}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>ملاحظات أو مقترحات</p>
                                                    <div class="container">
                                                        <div class="row g-2">
                                                            <div class="col-12">
                                                                <p className='fs-16 f-bold'> {data.item.notes}</p>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    )
}
