import React, { useEffect, useRef, useState } from 'react';
import BarChart from '../../components/charts/BarChart';
import Cards from '../../components/cards/Card';
import { CustomSelect } from '../../components/forms/CustomForms';
import { ButtonGroups, PageHeader } from '../../components/global';
import axiosInstance from '../../api/axiosInstance';
import { Tab, Tabs } from 'react-bootstrap';
import DoughnutChart from '../../components/charts/DoughnutChart';
import PieChart from '../../components/charts/PieChart';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import generatePDF from 'react-to-pdf';
import moment from 'moment';
export function ChartReviews() {
    const printRef = useRef();
    const printRef2 = useRef();
    const printRef3 = useRef();
    const [trips, setTrips] = useState("الرحلة الاولى")
    const [season, setseason] = useState(5)
    const [tripsCount, setTripsCount] = useState(1)
    const [show, setShow] = useState(false)
    const [rate, setRate] = useState({
        registration_form: { good: 0, average: 0, weak: 0 },
        appointments: { good: 0, average: 0, weak: 0 },
        comfort: { good: 0, average: 0, weak: 0 },
        instructions: { good: 0, average: 0, weak: 0 },
        volunteers: { good: 0, average: 0, weak: 0 },
        first_station: { good: 0, average: 0, weak: 0 },
        second_station: { good: 0, average: 0, weak: 0 },
        third_station: { good: 0, average: 0, weak: 0 },
        fourth_station: { good: 0, average: 0, weak: 0 },
        fifth_station: { good: 0, average: 0, weak: 0 },
        seventh_station: { good: 0, average: 0, weak: 0 },
        speech: { good: 0, average: 0, weak: 0 },
        food: { good: 0, average: 0, weak: 0 },
        overnight: { good: 0, average: 0, weak: 0 },
        lighting: { good: 0, average: 0, weak: 0 },
        acoustics: { good: 0, average: 0, weak: 0 },
        team: { good: 0, average: 0, weak: 0 },
        facebook: { good: 0, average: 0, weak: 0 },
        instagram: { good: 0, average: 0, weak: 0 },
        telegram: { good: 0, average: 0, weak: 0 },
    })
    const labels = ['جيد', "متوسط", "ضعيف"]
    const part = ["الرحلة الاولى", "الرحلة الثانية", "الرحلة الثالثة", "الرحلة الرابعة", "الرحلة الخامسة", "الرحلة السادسة", "الرحلة السابعة", "الرحلة الثامنة"];
    const seasons = [
        { key: 3, name: 'الثالث' },
        { key: 4, name: 'الرابع' },
        { key: 5, name: 'الخامس' },
    ]
    const getApiData = (name, subname, value) => {
        setRate(state => {
            return { ...state, [name]: { ...state[name], [subname]: value } }
        })
    }
    const url = `/reviews/api/v1/survey/?season=${season}&trips=${trips}`
    const getDataWithName = async (name) => {
        await axiosInstance.get(`${url}&${[name]}=${labels[0]}`)
            .then(res => {
                getApiData(name, 'good', res.data.count)
            })
        await axiosInstance.get(`${url}&${[name]}=${labels[1]}`)
            .then(res => {
                getApiData(name, 'average', res.data.count)
            })
        await axiosInstance.get(`${url}&${[name]}=${labels[2]}`)
            .then(res => {
                getApiData(name, 'weak', res.data.count)
            })
    }
    useEffect(() => {
        async function getData() {
            await axiosInstance.get(`${url}`)
                .then(res => {
                    setTripsCount(res.data.count)
                })
            getDataWithName('registration_form')
            getDataWithName('appointments')
            getDataWithName('comfort')
            getDataWithName('instructions')
            getDataWithName('volunteers')
            getDataWithName('first_station')
            getDataWithName('second_station')
            getDataWithName('third_station')
            getDataWithName('fourth_station')
            getDataWithName('fifth_station')
            getDataWithName('seventh_station')
            getDataWithName('speech')
            getDataWithName('food')
            getDataWithName('overnight')
            getDataWithName('lighting')
            getDataWithName('acoustics')
            getDataWithName('team')
            getDataWithName('facebook')
            getDataWithName('instagram')
            getDataWithName('telegram')
        }
        getData()
    }, [trips])
    const list = [
        {
            key: 1,
            title: 'سلاسة التسجيل في الرحلة',
            data: [rate.registration_form.good, rate.registration_form.average, rate.registration_form.weak]
        },
        {
            key: 2,
            title: 'مواعيد الاستعداد والالتحاق بسيارات النقل',
            data: [rate.appointments.good, rate.appointments.average, rate.appointments.weak]
        },
        {
            key: 3,
            title: 'الراحة في سيارات النقل',
            data: [rate.comfort.good, rate.comfort.average, rate.comfort.weak]
        },
        {
            key: 4,
            title: 'وضوح إرشادات الكادر في السيارات',
            data: [rate.instructions.good, rate.instructions.average, rate.instructions.weak]
        },
        {
            key: 5,
            title: 'عمل المتطوعين',
            data: [rate.volunteers.good, rate.volunteers.average, rate.volunteers.weak]
        },
        {
            key: 19,
            title: 'المحطة الاولى (المقام)',
            data: [rate.first_station.good, rate.first_station.average, rate.first_station.weak]
        },
        {
            key: 20,
            title: 'المحطة الثانية (المعلم)',
            data: [rate.second_station.good, rate.second_station.average, rate.second_station.weak]
        },
        {
            key: 6,
            title: 'المحطة الثالثة (النخلة)  براوجلي (الحجية)',
            data: [rate.third_station.good, rate.third_station.average, rate.third_station.weak]
        },
        {
            key: 7,
            title: 'المحطة الرابعة (التل)',
            data: [rate.fourth_station.good, rate.fourth_station.average, rate.fourth_station.weak]
        },
        {
            key: 8,
            title: 'المحطة الخامسة (الساتر)',
            data: [rate.fifth_station.good, rate.fifth_station.average, rate.fifth_station.weak]
        },
        {
            key: 9,
            title: 'المحطة السادسة (النصب) (الشمسية)',
            data: [rate.seventh_station.good, rate.seventh_station.average, rate.seventh_station.weak]
        },
        {
            key: 10,
            title: 'وضوح الرواة بالطرح والكلام',
            data: [rate.speech.good, rate.speech.average, rate.speech.weak]
        },
        {
            key: 11,
            title: 'جودة وجبات الطعام',
            data: [rate.food.good, rate.food.average, rate.food.weak]
        },
        {
            key: 12,
            title: 'راحة مكان المبيت',
            data: [rate.overnight.good, rate.overnight.average, rate.overnight.weak]
        },
        {
            key: 13,
            title: 'جودة الإضاءة',
            data: [rate.lighting.good, rate.lighting.average, rate.lighting.weak]
        },
        {
            key: 14,
            title: 'جودة الصوتيات',
            data: [rate.acoustics.good, rate.acoustics.average, rate.acoustics.weak]
        },
        {
            key: 15,
            title: 'جودة تعامل الكادر',
            data: [rate.team.good, rate.team.average, rate.team.weak]
        },
        {
            key: 16,
            title: 'منصة معراج على الفيسبوك',
            data: [rate.facebook.good, rate.facebook.average, rate.facebook.weak]
        },
        {
            key: 17,
            title: 'منصة معراج على الانستكرام',
            data: [rate.instagram.good, rate.instagram.average, rate.instagram.weak]
        },
        {
            key: 18,
            title: 'منصة معراج على التلكرام',
            data: [rate.telegram.good, rate.telegram.average, rate.telegram.weak]
        },
    ]
    const percentage = (value) => { return value ? Math.round((value / tripsCount) * 100) : 0 }

    const Header = () => (
        <>
            <img src={require('../../styles/images/dashboard_img/logo.png')} alt="" class="watermark" />
            <div class="page-footer m-0 p-0">
                <div class='center'>
                    <p>المديرية العامة
                        <br />للعلاقات والخدمات الأجتماعية</p>
                </div>
                <div class='center text-center'>
                    <p className='m-0 p-0'>تم انشاء التقرير
                        بأستخدام نظام بيان
                        <p className='fs-10'>قسم تقنية المعلومات والأتصالات</p>
                    </p>
                </div>
                <div class='center'>
                    <p>التاريخ : {moment().format('YYYY-MM-DD hh:mm:ss')}</p>
                </div>
            </div>
        </>
    )
    return (
        <div>
            <div class="container-fluid">
                <div class="row justify-content-center align-items-center g-2">
                    <div class="col-xl-12">
                        <PageHeader
                            title={`تقارير التقييمات`}
                            links={[]}
                        />
                    </div>
                    <div class="col-xl-12">
                        <ButtonGroups path='#' title='الرجوع' icon='fas fa-chevron-right' />
                        <ButtonGroups path={'/reviews/list/'} title={'قائمة التقييمات'} icon='fas fa-list-alt' />
                    </div>
                    <div className='col-12'>
                        <Cards hasBody title='تحديد الرحلة' >
                            <div
                                class="container"
                            >
                                <div
                                    class="row justify-content-center align-items-center g-2"
                                >
                                    <div class="col">
                                        <label htmlFor="">الموسم</label>
                                        <CustomSelect
                                            icon='date_range'
                                            name='trips'
                                            defaultValue={season}
                                            onChange={(e) => setseason(e === undefined ? '' : e)}
                                            onClear={() => setseason('')}
                                        >
                                            {seasons.map(item => <option value={item.key}>{item.name}</option>)}
                                        </CustomSelect>
                                    </div>
                                    <div class="col">
                                        <label htmlFor="">الرحلة</label>
                                        <CustomSelect
                                            icon='date_range'
                                            name='trips'
                                            defaultValue={trips}
                                            onChange={(e) => setTrips(e === undefined ? '' : e)}
                                            onClear={() => setTrips('')}
                                        >
                                            {part.map(item => <option value={item}>{item}</option>)}
                                        </CustomSelect>
                                    </div>
                                </div>
                            </div>


                        </Cards>

                    </div>

                    <div class="col-12">
                        <Tabs
                            defaultActiveKey="home"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="home" title={<p className='my-1 d-flex justify-content-center align-items-center'><i class="material-icons ps-2">donut_large</i> مخطط دونات</p>}>

                                <div className='my-0 p d-flex justify-content-end align-items-center'>
                                    <ReactToPrint
                                        trigger={() => <ButtonGroups path='#' title='طباعة التقرير' icon='fas fa-print' />}
                                        content={() => printRef.current}
                                    />
                                </div>
                                <div class="container-fluid m-0 p-0 print-content" ref={printRef}>
                                    <div class="row justify-content-center align-items-center g-3">
                                        <Header />
                                        {list.map(item => {
                                            return (
                                                <div key={item.key} class="col-xl-6 col-lg-6 col-12">
                                                    <Cards subtitle={'تقييم ' + trips} title={item.title} >
                                                        <p className='p-1 fs-14  m-0'>
                                                            <span className='p-1'>عدد التقييمات :</span>
                                                            <span className='p-1'>
                                                                <i class="fa-solid fa-square p-1" style={{ color: '#00800075' }}></i>
                                                                {labels[0]} : {item.data[0]}
                                                            </span>
                                                            <span className='p-1'>
                                                                <i class="fa-solid fa-square p-1" style={{ color: '#ffff0075' }}></i>
                                                                {labels[1]} : {item.data[1]}
                                                            </span>
                                                            <span className='p-1'>
                                                                <i class="fa-solid fa-square p-1" style={{ color: '#f0000085' }}></i>
                                                                {labels[2]} : {item.data[2]}
                                                            </span>
                                                        </p>
                                                        <div className='position-relative d-flex justify-content-center align-items-center'>
                                                            <div className='rate fs-14  m-0'>
                                                                <p className='p-1'>
                                                                    <i class="fa-solid fa-square p-1" style={{ color: '#00800075' }}></i>
                                                                    {labels[0]} : {percentage(item.data[0])}%
                                                                </p>
                                                                <p className='p-1'>
                                                                    <i class="fa-solid fa-square p-1" style={{ color: '#ffff0075' }}></i>
                                                                    {labels[1]} : {percentage(item.data[1])}%
                                                                </p>
                                                                <p className='p-1'>
                                                                    <i class="fa-solid fa-square p-1" style={{ color: '#f0000085' }}></i>
                                                                    {labels[2]} : {percentage(item.data[2])}%
                                                                </p>
                                                            </div>
                                                            <DoughnutChart
                                                                labels={labels}
                                                                data={item.data}
                                                            />
                                                        </div>

                                                    </Cards>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="profile" title={<p className='my-1 d-flex justify-content-center align-items-center'><i class="material-icons ps-2">bar_chart</i> الرسم البياني</p>}>

                                <div className='my-0 p d-flex justify-content-end align-items-center'>
                                    <ReactToPrint
                                        trigger={() => <ButtonGroups path='#' title='طباعة التقرير' icon='fas fa-print' />}
                                        content={() => printRef2.current}
                                    />
                                </div>
                                <div class="container-fluid m-0 p-0" ref={printRef2}>
                                    <div class="row justify-content-center align-items-center g-3">
                                        <Header />
                                        {list.map(item => {
                                            return (
                                                <div key={item.key} class="col-xl-6 col-lg-6 col-12">
                                                    <Cards subtitle={'تقييم ' + trips} title={item.title} >
                                                        <p className='p-1 fs-14  m-0'>
                                                            <span className='p-1 '>عدد التقييمات :</span>
                                                            <span className='p-1'>
                                                                <i class="fa-solid fa-square p-1" style={{ color: '#00800075' }}></i>
                                                                {labels[0]} : {item.data[0]}
                                                            </span>
                                                            <span className='p-1'>
                                                                <i class="fa-solid fa-square p-1" style={{ color: '#ffff0075' }}></i>
                                                                {labels[1]} : {item.data[1]}
                                                            </span>
                                                            <span className='p-1'>
                                                                <i class="fa-solid fa-square p-1" style={{ color: '#f0000085' }}></i>
                                                                {labels[2]} : {item.data[2]}
                                                            </span>
                                                        </p>
                                                        <p className='p-1 fs-14  m-0'>
                                                            <span className='p-1'>النسبة المئوية :</span>
                                                            <span className='p-1'>
                                                                <i class="fa-solid fa-square p-1" style={{ color: '#00800075' }}></i>
                                                                {labels[0]} : {percentage(item.data[0])}%
                                                            </span>
                                                            <span className='p-1'>
                                                                <i class="fa-solid fa-square p-1" style={{ color: '#ffff0075' }}></i>
                                                                {labels[1]} : {percentage(item.data[1])}%
                                                            </span>
                                                            <span className='p-1'>
                                                                <i class="fa-solid fa-square p-1" style={{ color: '#f0000085' }}></i>
                                                                {labels[2]} : {percentage(item.data[2])}%
                                                            </span>
                                                        </p>

                                                        <BarChart
                                                            labels={labels}
                                                            data={item.data}
                                                        />
                                                    </Cards>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="pie" title={<p className='my-1 d-flex justify-content-center align-items-center'><i class="material-icons ps-2">pie_chart</i> مخطط دائري</p>}>

                                <div className='my-0 p d-flex justify-content-end align-items-center'>
                                    <ReactToPrint
                                        trigger={() => <ButtonGroups path='#' title='طباعة التقرير' icon='fas fa-print' />}
                                        content={() => printRef3.current}
                                    />
                                </div>
                                <div class="container-fluid m-0 p-0" ref={printRef3}>
                                    <div class="row justify-content-center align-items-center g-3">
                                        <Header />
                                        {list.map(item => {
                                            return (
                                                <div key={item.key} class="col-xl-6 col-lg-6 col-12">
                                                    <Cards subtitle={'تقييم ' + trips} title={item.title} >
                                                        <p className='p-1 fs-14 m-0'>
                                                            <span className='p-1'>عدد التقييمات :</span>
                                                            <span className='p-1'>
                                                                <i class="fa-solid fa-square p-1" style={{ color: '#00800075' }}></i>
                                                                {labels[0]} : {item.data[0]}
                                                            </span>
                                                            <span className='p-1'>
                                                                <i class="fa-solid fa-square p-1" style={{ color: '#ffff0075' }}></i>
                                                                {labels[1]} : {item.data[1]}
                                                            </span>
                                                            <span className='p-1'>
                                                                <i class="fa-solid fa-square p-1" style={{ color: '#f0000085' }}></i>
                                                                {labels[2]} : {item.data[2]}
                                                            </span>
                                                        </p>
                                                        <p className='p-1 fs-14  m-0'>
                                                            <span className='p-1'>النسبة المئوية :</span>
                                                            <span className='p-1'>
                                                                <i class="fa-solid fa-square p-1" style={{ color: '#00800075' }}></i>
                                                                {labels[0]} : {percentage(item.data[0])}%
                                                            </span>
                                                            <span className='p-1'>
                                                                <i class="fa-solid fa-square p-1" style={{ color: '#ffff0075' }}></i>
                                                                {labels[1]} : {percentage(item.data[1])}%
                                                            </span>
                                                            <span className='p-1'>
                                                                <i class="fa-solid fa-square p-1" style={{ color: '#f0000085' }}></i>
                                                                {labels[2]} : {percentage(item.data[2])}%
                                                            </span>
                                                        </p>
                                                        <PieChart
                                                            labels={labels}
                                                            data={item.data}
                                                        />
                                                    </Cards>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </Tab>
                        </Tabs>

                    </div>
                </div>
            </div>
        </div>
    )
}
