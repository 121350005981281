import React, { useState, useEffect } from 'react';
import { CustomDatePicker, CustomInput, CustomSelect, CustomTreeSelect } from '../forms/CustomForms';
import { Checkbox, Input } from 'antd';
import { ImageUpload } from '../forms/ImageUpload';
import { Button, Modal, Offcanvas } from 'react-bootstrap';
import axiosInstance from '../../api/axiosInstance';
import { AddPop, ErrorPop } from '../forms/PopForm';

export function EditFormSurvey({ item, id, open, onClose, type, onPut }) {
    // forms valus to post data
    const [formValues, setFormValues] = useState(item)
    // select data in form
    const [formData, setFormData] = useState({
        managements: [],
    })
    // search values to select forms
    const [formSearch, setFormSearch] = useState({
        managements: '',
    })
    // search values to select forms
    const [formPop, setFormPop] = useState({
        showOne: false,
        showTwo: false,
        showError: false,
        errorMessage: '',
    })
    const [view, setView] = useState({
        image: item !== null ? item.image : null,
    })
    const [post, setPost] = useState({
        progress: 0,
    })
    // func to change values in form
    const change = (name, value) => {
        setFormValues((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to change file values in form
    const changeFile = (name, value) => {
        if (value[0] !== undefined) {
            const file = URL.createObjectURL(value[0])
            setView((state) => {
                return { ...state, [name]: file }
            })
            setFormValues((state) => {
                return { ...state, [name]: value[0] }
            })
        } else {
            setView((state) => {
                return { ...state, [name]: null }
            })
            setFormValues((state) => {
                return { ...state, [name]: '' }
            })
        }
    }
    // func to get data from api and push it to state
    const getFormData = (name, value) => {
        setFormData((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to search in api and push it to state
    const searchForm = (name, value) => {
        setFormSearch((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to change values in pop form
    const popForm = (name, value) => {
        setFormPop((state) => {
            return { ...state, [name]: value }
        })
    }
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    // get data from api


    // pust data to api
    const url ='/m3raaj/api/v1/survey/' ;
    const postData = async (event) => {
        event.preventDefault()
        const UploadProgress = (event) => {
            const { loaded, total } = event;
            let percent = Math.floor((loaded * 100) / total)
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            changePost('progress', percent)
        }

        const option = {
            onUploadProgress: UploadProgress
        }

        await axiosInstance.put(`${url}${id}/`, formValues, option)
            .catch(error => {
                popForm('showTwo', false)
                popForm('showError', true)
                changePost('progress', 0)
                if (error.message === 'Request failed with status code 401') {
                    popForm('errorMessage', 'لا يوجد أتصال بالانترنيت')
                } else if (error.message === 'Request failed with status code 400') {
                    popForm('errorMessage', 'هذا العنصر موجود أو هنالك نقص في المعلومات')
                } else if (error.message === 'Network Error') {
                    popForm('errorMessage', 'لا يوجد أتصال بالانترنيت')
                }
            }).then(res => {
                if (res.status === 200) {
                    onPut()
                    changePost('progress', 0)
                    popForm('showTwo', false)
                    onClose()
                }
            })
    }
    const part = [
        "16/02/2024 - الرحلة الاولى",
        "01/03/2024 - الرحلة الثانية",
        "08/03/2024 - الرحلة الثالثة",
        // "10/11 / 2023 - الرحلة الرابعة",
        // "17 /11/2023 - الرحلة الخامسة",
        // "24 / 11 / 2023 - الرحلة السادسة",
        // "01 / 12 / 2023 - الرحلة السابعة",
        // "08 / 12 / 2023 - الرحلة الثامنة"
    ]
    const part2 = ["16/02/2024 - الرحلة الاولى", "01/03/2024 - الرحلة الثانية",/* "03/11/2023 - الرحلة الثالثة", "10/11/2023 - الرحلة الرابعة", "17/11/2023 - الرحلة الخامسة", "24/11/2023 - الرحلة السادسة", "01/12/2023 - الرحلة السابعة", "08/12/2023 - الرحلة الثامنة"*/]
    if (!item) return null
    return (
        <>
            <Offcanvas show={open} onHide={onClose} className='offcanvas-edit'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>تعديل</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <form>
                        <div className='container-fluid'>
                            <div class="row justify-content-center g-2">
                                <div className='col-12'>
                                    <label>الرحلة</label>
                                    <CustomSelect
                                        icon='date_range'
                                        name='trips'
                                        onChange={(e) => change('trips', e === undefined ? '' : e)}
                                        onClear={() => change('trips', '')}
                                        defaultValue={item.trips}
                                    >
                                        {part.map(item => <option value={item}>{item}</option>)}
                                    </CustomSelect>
                                </div>
                                <div className='col-12'>
                                    <label className='optional'>حالة الرحلة</label>
                                    <CustomSelect
                                        icon='done'
                                        name='is_active'
                                        onChange={(e) => change('is_active', e === undefined ? '' : e)}
                                        onClear={() => change('is_active', '')}
                                        defaultValue={item.is_active}
                                    >
                                        <option value={true}>تم الحضور</option>
                                        <option value={false}>لم يتم الحضور</option>

                                    </CustomSelect>
                                </div>
                                <div className='col-12' >
                                    <Button
                                        type='button'
                                        variant=''
                                        onClick={() => popForm('showOne', true)}
                                        className='but-all w-100'
                                    >تعديل البيانات</Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Offcanvas.Body>
            </Offcanvas>
            <AddPop
                showOne={formPop.showOne}
                showTwo={formPop.showTwo}
                handleshow={(e) => {
                    popForm('showOne', false)
                    postData(e)
                    popForm('showTwo', true)
                }}
                handleCloseOne={() => popForm('showOne', false)}
                handleCloseTwo={() => popForm('showTwo', false)}
                post={post.progress}
            />
            <ErrorPop
                showError={formPop.showError}
                handleCloseError={() => popForm('showError', false)}
                errorMessage={formPop.errorMessage}
            />
        </>
    )
}

