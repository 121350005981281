import React, { useEffect } from 'react'
import { useState } from 'react'
import axiosInstance from '../../api/axiosInstance'
import { useCookies } from 'react-cookie';
import { PageHeader, ButtonGroups, PaginationPage } from '../../components/global';
import { SortReviews, TableReviews } from '../../components/reviews';
import { downlodXlsx } from '../../var_func/var_func';
import { customDataReviews } from './data';
import { ReportPop } from '../../components/forms/PopForm';

export function ListReviews({ type }) {
    const [page, setPage] = useCookies('');
    const { page_Reviews } = page;
    const [data, setData] = useState({
        list: [],
        listReport: [],
        listCount: 0,
    })
    const [open, setOpen] = useState({
        one: false,
        two: false,
        report: false,
    });
    const drawer = (name, value) => {
        setOpen((state) => {
            return { ...state, [name]: value }
        })
    }
    const getListData = (name, value) => {
        setData((state) => {
            return { ...state, [name]: value }
        })
    }
    const url = '/reviews/api/v1/survey/';
    const title = 'التقييمات';
    useEffect(() => {
        //get death
        async function getData() {
            await axiosInstance.get(`${url}?page_size=100&page=${page_Reviews ? page_Reviews : 1}`)
                .then(res => {
                    getListData('list', res.data.results)
                    getListData('listCount', res.data.count)
                }).catch(err => {
                    if (err.response.data.detail === 'Invalid page.') {
                        setPage('page_Reviews', 1)
                    }
                })
        }
        getData()
    }, [page_Reviews, type])
    useEffect(() => {
        //get death

        async function getData() {
            if (open.report) {
                await axiosInstance.get(`${url}?page_size=${data.listCount}`)
                    .then(res => {
                        getListData('listReport', res.data.results)
                    }).catch(err => {
                        drawer('report', false)
                    })
            }

        }
        getData()
    }, [page_Reviews, type, open.report])
    const onButtonReport = () => {
        downlodXlsx(customDataReviews(data.listReport), 'تقرير التقييمات ' + title + Math.floor(Math.random() * 100000))
    }
    return (
        <>
            <div class="container-fluid">
                <div class="row justify-content-center align-items-center g-2">
                    <div class="col-xl-12">
                        <div class="container-fluid">
                            <div class="row justify-content-center align-items-center g-2">
                                <div class="col-xl-12">
                                    <PageHeader
                                        title={'قائمة ' + title}
                                        links={[
                                            {
                                                name: title,
                                                path: '#'
                                            }
                                        ]}
                                    />
                                </div>
                                <div class="col-xl-12">
                                    <ButtonGroups path='#' title='الرجوع' icon='fas fa-chevron-right' />
                                    <ButtonGroups title='فرز البيانات' onClick={() => drawer('one', true)} icon='fas fa-sort' />
                                    <ButtonGroups title='تحميل البيانات' onClick={() => drawer('report', true)} icon='fas fa-file' />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <p className='text-light'>عدد التقييمات : {data.listCount}</p>
                        <TableReviews
                            data={data.list}
                            type={type}
                            page={page_Reviews}
                        />
                    </div>
                    <div className="col-12">
                        <PaginationPage
                            defaultCurrent={!page_Reviews ? 1 : Number(page_Reviews)}
                            total={data.listCount}
                            defaultPageSize={100}
                            onChange={(e) => setPage('page_Reviews', e === undefined ? '' : e)}
                        />
                    </div>
                </div>
            </div>
            <div>
                <SortReviews
                    type={type}
                    open={open.one}
                    onClose={() => drawer('one', false)}
                />
                <ReportPop
                    open={open.report}
                    title={title}
                    onClose={() => drawer('report', false)}
                    onOK={onButtonReport}
                />
            </div>
        </>
    )
}