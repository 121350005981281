import Home from "./pages/Home";
import Settings from "./pages/Settings";
import { AddAccounts, HomeAccounts, ItemsAccounts, ListAccounts, Profile } from "./pages/accounts";
import { ChartReviews, ItemsReviews, ListReviews, NotesReviews } from "./pages/reviews";
import { ItemsSurvey, ListSurvey } from "./pages/survey";


export const routes = [

    /*------------------
accounts router
--------------------*/
    // {
    //     name: 'accounts_home',
    //     path: '/accounts/home/',
    //     element: HomeAccounts,
    //     type: [0],
    //     group: [0, 1, 2],
    // },
    {
        name: 'list_survey_list',
        path: '/emirli/list/',
        element: ListSurvey,
        survey: 1,
        type: [0, 1, 2],
        group: [0, 1],
    },
    
    {
        name: 'list_survey_list',
        path: '/baiji/list/',
        element: ListSurvey,
        survey: 2,
        type: [0, 1, 2],
        group: [0, 1],
    },
    {
        name: 'list_survey_list_item',
        path: '/emirli/list/item/id/:id/name/:name',
        element: ItemsSurvey,
        survey: 1,
        type: [0, 1, 2],
        group: [0, 1],
    },
    {
        name: 'list_survey_list_item',
        path: '/baiji/list/item/id/:id/name/:name',
        element: ItemsSurvey,
        survey: 2,
        type: [0, 1, 2],
        group: [0, 1],
    },

    {
        name: 'list_reviews_list',
        path: '/reviews/list/',
        element: ListReviews,
        type: [0, 1, 2],
        group: [0, 1],
    },
    {
        name: 'list_survey_reviews_list_item',
        path: '/reviews/list/item/id/:id/',
        element: ItemsReviews,
        type: [0, 1, 2],
        group: [0, 1],
    },
    {
        name: 'list_survey_reviews_list_item',
        path: '/reviews/report/',
        element: ChartReviews,
        type: [0, 1, 2],
        group: [0, 1],
    },
    {
        name: 'list_survey_reviews_list_item',
        path: '/reviews/notes/',
        element: NotesReviews,
        type: [0, 1, 2],
        group: [0, 1],
    },
    {
        name: 'accounts_add',
        path: '/accounts/add/',
        element: AddAccounts,
        type: [0],
        group: [0, 2],
    },

    {
        name: 'accounts_list',
        path: '/accounts/list/',
        element: ListAccounts,
        type: [0],
        group: [0, 2],
    },

    {
        name: 'accounts_item',
        path: '/accounts/list/id/:id/name/:name',
        element: ItemsAccounts,
        type: [0],
        group: [0, 2],
    },
    {
        name: 'settings',
        path: '/settings/',
        element: Settings,
        type: [0],
        group: [0],
    },
    {
        name: 'profile',
        path: '/profile/',
        element: Profile,
        type: [0, 1, 2],
        group: [0, 1,2],
    },
]
