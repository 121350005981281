import React, { useState, useEffect } from 'react';
import { CustomDatePicker, CustomInput, CustomSelect, CustomTreeSelect } from '../forms/CustomForms';
import { Checkbox, Input } from 'antd';
import { ImageUpload } from '../forms/ImageUpload';
import { Button, Modal, Offcanvas } from 'react-bootstrap';
import axiosInstance from '../../api/axiosInstance';
import { AddPop, ErrorPop } from '../forms/PopForm';
import { treeData } from './Setings';

export function EditFormAccounts({ item, id, open, onClose, type, onPut }) {
    // forms valus to post data
    const [formValues, setFormValues] = useState(item)
    // select data in form
    const [formData, setFormData] = useState({
        managements: [],
    })
    // search values to select forms
    const [formSearch, setFormSearch] = useState({
        managements: '',
    })
    // search values to select forms
    const [formPop, setFormPop] = useState({
        showOne: false,
        showTwo: false,
        showError: false,
        errorMessage: '',
    })
    const [view, setView] = useState({
        image: item !== null ? item.image : null,
    })
    const [post, setPost] = useState({
        progress: 0,
    })
    // func to change values in form
    const change = (name, value) => {
        setFormValues((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to change file values in form
    const changeFile = (name, value) => {
        if (value[0] !== undefined) {
            const file = URL.createObjectURL(value[0])
            setView((state) => {
                return { ...state, [name]: file }
            })
            setFormValues((state) => {
                return { ...state, [name]: value[0] }
            })
        } else {
            setView((state) => {
                return { ...state, [name]: null }
            })
            setFormValues((state) => {
                return { ...state, [name]: '' }
            })
        }
    }
    // func to get data from api and push it to state
    const getFormData = (name, value) => {
        setFormData((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to search in api and push it to state
    const searchForm = (name, value) => {
        setFormSearch((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to change values in pop form
    const popForm = (name, value) => {
        setFormPop((state) => {
            return { ...state, [name]: value }
        })
    }
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    // get data from api
    // pust data to api

    const postData = async (event) => {
        event.preventDefault()
        const UploadProgress = (event) => {
            const { loaded, total } = event;
            let percent = Math.floor((loaded * 100) / total)
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            changePost('progress', percent)
        }

        const option = {

            onUploadProgress: UploadProgress
        }
        const fileData = {};
        let ignored = ['id', 'is_active', 'repassword', "password"];
        for (let key in formValues) {
            if (ignored.includes(key)) {
                continue;
            }
            else {
                fileData[key] = formValues[key]
            }
        }
        await axiosInstance.put(`/accounts/api/v1/users/${id}/`, fileData, option)
            .catch(error => {
                popForm('showTwo', false)
                popForm('showError', true)
                changePost('progress', 0)
                if (error.request.status === 400) {
                    popForm('errorMessage', 'هنالك نقص في المعلومات')
                } else if (error.message === 'Network Error') {
                    popForm('errorMessage', 'لا يوجد أتصال بالانترنيت')
                }
                else if (error.request.status === 406) {
                    popForm('errorMessage', 'اسم المستخدم يجب ان يكون مكون من اربع كاركترات')
                }
            }).then(res => {
                if (res.status === 200) {
                    onPut()
                    changePost('progress', 0)
                    popForm('showTwo', false)
                    onClose()
                }
            })
    }
    if (!item) return null
    return (
        <>
            <Offcanvas show={open} onHide={onClose} className='offcanvas-edit'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>تعديل</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <form>
                        <div className='container-fluid'>
                            <div class="row justify-content-center g-2">
                                {type === 777 || type === 1 ?
                                    <>
                                        <div className='col-12'>
                                            <label className='optional'>الإسم</label>
                                            <CustomInput type="text" icon='subtitles' defaultValue={item.name} name='name' placeholder="الإسم الأول" onChange={(e) => change(e.target.name, e.target.value)} required />
                                        </div>
                                        <div className='col-12'>
                                            <label className='optional'>اسم المستخدم</label>
                                            <CustomInput type="text" icon='alternate_email' defaultValue={item.username} name='username' placeholder="اسم المستخدم" onChange={(e) => change(e.target.name, e.target.value)} required />
                                        </div>
                                        <div className='col-12'>
                                            <label className='optional'>محل السكن</label>
                                            <CustomInput type="text" icon='place' defaultValue={item.address} name='address' placeholder="محل السكن" onChange={(e) => change(e.target.name, e.target.value)} required />
                                        </div>
                                        <div className='col-12'>
                                            <label className='optional'>رقم الهاتف</label>
                                            <CustomInput type="text" icon='phone' name='phone' maxLength={11} defaultValue={item.phone} placeholder="رقم الهاتف" onChange={(e) => change(e.target.name, e.target.value)} required />
                                        </div>

                                    </>
                                    :
                                    null
                                }
                                {type === 777 || type === 3 ?
                                    <>
                                        <div className='col-12'>
                                            <label>الصلاحية</label>
                                            <CustomTreeSelect
                                                icon='rule'
                                                name='type'
                                                defaultValue={item.type}
                                                onChange={(e) => change('type', e === undefined ? '' : e)}
                                                onClear={() => change('type', '')}
                                                treeData={treeData}
                                            />
                                        </div>
                                        <div className='col-12'>
                                            <label>نوع الصلاحية</label>
                                            <CustomSelect
                                                icon='rule'
                                                name='group_type'
                                                defaultValue={item.group_type}
                                                onChange={(e) => change('group_type', e === undefined ? '' : e)}
                                                onClear={() => change('group_type', '')}
                                            >
                                                <option value={0}>مدير</option>
                                                {formValues.type === 0 && <option value={2}>مدير مستخدمين</option>}
                                                {formValues.type !== 0 &&<option value={1}>محرر</option>}

                                            </CustomSelect>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                <div className='col-12' >
                                    <Button
                                        type='button'
                                        variant=''
                                        onClick={() => popForm('showOne', true)}
                                        className='but-all w-100'
                                    >تعديل البيانات</Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Offcanvas.Body>
            </Offcanvas>
            <AddPop
                showOne={formPop.showOne}
                showTwo={formPop.showTwo}
                handleshow={(e) => {
                    popForm('showOne', false)
                    postData(e)
                    popForm('showTwo', true)
                }}
                handleCloseOne={() => popForm('showOne', false)}
                handleCloseTwo={() => popForm('showTwo', false)}
                post={post.progress}
            />
            <ErrorPop
                showError={formPop.showError}
                handleCloseError={() => popForm('showError', false)}
                errorMessage={formPop.errorMessage}
            />
        </>
    )
}

export function EditPasswordAccounts({ item, id, open, onClose, type, onPut }) {
    // forms valus to post data
    const [formValues, setFormValues] = useState({
        password: ''
    })
    // select data in form
    const [formData, setFormData] = useState({
        managements: [],
    })
    // search values to select forms
    const [formSearch, setFormSearch] = useState({
        managements: '',
    })
    // search values to select forms
    const [formPop, setFormPop] = useState({
        showOne: false,
        showTwo: false,
        showError: false,
        errorMessage: '',
    })
    const [view, setView] = useState({
        image: item !== null ? item.image : null,
    })
    const [post, setPost] = useState({
        progress: 0,
    })
    // func to change values in form
    const change = (name, value) => {
        setFormValues((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to change file values in form
    const changeFile = (name, value) => {
        if (value[0] !== undefined) {
            const file = URL.createObjectURL(value[0])
            setView((state) => {
                return { ...state, [name]: file }
            })
            setFormValues((state) => {
                return { ...state, [name]: value[0] }
            })
        } else {
            setView((state) => {
                return { ...state, [name]: null }
            })
            setFormValues((state) => {
                return { ...state, [name]: '' }
            })
        }
    }
    // func to get data from api and push it to state
    const getFormData = (name, value) => {
        setFormData((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to search in api and push it to state
    const searchForm = (name, value) => {
        setFormSearch((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to change values in pop form
    const popForm = (name, value) => {
        setFormPop((state) => {
            return { ...state, [name]: value }
        })
    }
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    // get data from api


    // pust data to api

    const postData = async (event) => {
        event.preventDefault()
        const UploadProgress = (event) => {
            const { loaded, total } = event;
            let percent = Math.floor((loaded * 100) / total)
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            changePost('progress', percent)
        }

        const option = {

            onUploadProgress: UploadProgress
        }
        // const fileData = {};
        // let ignored = ['id', 'is_active', 'repassword', "password"];
        // for (let key in formValues) {
        //     if (ignored.includes(key)) {
        //         continue;
        //     }
        //     else {
        //         fileData[key] = formValues[key]
        //     }
        // }
        await axiosInstance.put(`/accounts/api/v1/users/password/${id}/`, formValues, option)
            .catch(error => {
                popForm('showTwo', false)
                popForm('showError', true)
                changePost('progress', 0)
                if (error.message === 'Request failed with status code 401') {
                    popForm('errorMessage', 'لا يوجد أتصال بالانترنيت')
                } else if (error.message === 'Request failed with status code 400') {
                    popForm('errorMessage', 'هذا العنصر موجود أو هنالك نقص في المعلومات')
                } else if (error.message === 'Network Error') {
                    popForm('errorMessage', 'لا يوجد أتصال بالانترنيت')
                }
            }).then(res => {
                if (res.status === 200) {
                    onPut()
                    changePost('progress', 0)
                    popForm('showTwo', false)
                    onClose()
                }
            })
    }
    if (!item) return null
    return (
        <>
            <Offcanvas show={open} onHide={onClose} className='offcanvas-edit'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>تغير كلمة المرور</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <form>
                        <div className='container-fluid'>
                            <div class="row justify-content-center g-2">
                                <div className='col-md-12 col-12'>
                                    <label className='optional'>كلمة المرور</label>
                                    <Input.Password size='large' name='password' placeholder="كلمة المرور" onChange={(e) => change(e.target.name, e.target.value)} required />
                                </div>
                                <div className='col-md-12 col-12'>

                                    <Input.Password size='large' name='repassword' placeholder="اعادة كلمة المرور" onChange={(e) => change(e.target.name, e.target.value)} required />
                                </div>
                                <div className='col-md-12 col-12'>
                                    <p className='text-black-50'>
                                        {formValues.password ?
                                            (
                                                formValues.password === formValues.repassword ?
                                                    'كلمة المرور متتطابقة'
                                                    :
                                                    'كلمة المرور غير متتطابقة'
                                            )
                                            :
                                            ''
                                        }
                                    </p>
                                </div>


                                <div className='col-12' >
                                    <Button
                                        type='button'
                                        variant=''
                                        onClick={() => popForm('showOne', true)}
                                        className='but-all w-100'
                                    >تغير كلمة المرور</Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Offcanvas.Body>
            </Offcanvas>
            <AddPop
                showOne={formPop.showOne}
                showTwo={formPop.showTwo}
                handleshow={(e) => {
                    popForm('showOne', false)
                    postData(e)
                    popForm('showTwo', true)
                }}
                handleCloseOne={() => popForm('showOne', false)}
                handleCloseTwo={() => popForm('showTwo', false)}
                post={post.progress}
            />
            <ErrorPop
                showError={formPop.showError}
                handleCloseError={() => popForm('showError', false)}
                errorMessage={formPop.errorMessage}
            />
        </>
    )
}
export function EditStatusAccounts(props) {
    const { onPut, onClose, open, is_active, id } = props;
    const Status = async () => {
        const data = {
            id,
            is_active: is_active ? false : true
        }
        await axiosInstance.put(`/accounts/api/v1/change_status/`, data)
            .then(res => {
                if (res.status === 200) {
                    onClose()
                    onPut()
                }
            })
    }
    return (
        <Modal
            size="md"
            centered={true}
            show={open}
            onHide={onClose}
            style={{ zIndex: 5000 }}
        >
            <Modal.Header closeButton>
                <Modal.Title>{is_active ? 'تعطيل الحساب' : 'تفعيل الحساب'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <div>
                        {is_active ? <i class="fa-solid fa-user-slash text-danger" style={{ fontSize: 100 }}></i> : <i class="fa-solid fa-user text-success" style={{ fontSize: 100 }}></i>}</div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    ألغاء
                </Button>
                <Button variant="" className='but-all' desplay onClick={Status}>
                    {is_active ? 'تعطيل الحساب' : 'تفعيل الحساب'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}