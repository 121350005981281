import React from 'react'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
export function TableSurvey({ data, page, type }) {
    const path = type === 1 ? '/emirli/list/' : '/baiji/list/';
    const NameCell = ({ rowData, dataKey, ...props }) => (
        <Cell {...props}>
            <Link
                style={{ color: '#000' }}
                to={`${path}item/id/${rowData.id}/name/${rowData.name}`}
            >
                {rowData.name}
            </Link>
        </Cell>
    );

    const DateCell = ({ rowData, dataKey, ...props }) => (<Cell dir='ltr' {...props}>{moment(rowData.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Cell>);
    // const GroupCell = ({ rowData, dataKey, ...props }) => (<Cell {...props}>{userGroup(rowData.group_type)}</Cell>);
    // const StatusCell = ({ rowData, dataKey, ...props }) => (<Cell {...props}>{rowData.is_active ? 'تم الحضور' : "لم يتم الحضور"}</Cell>);
    const StatusCell = ({ rowData, dataKey, ...props }) => (<Cell {...props}>{rowData.is_active ? 'تم الحضور' : "لم يتم الحضور"}</Cell>);
    const HasChildrenCell = ({ rowData, dataKey, ...props }) => (<Cell {...props}>{rowData.has_children ? 'نعم يوجد اطفال' : "لا يوجد اطفال"}</Cell>);
    const ChildrenCountCell = ({ rowData, dataKey, ...props }) => (<Cell {...props}>{rowData.children_count ? rowData.children_count : "لا يوجد اطفال"}</Cell>);
    const PreviousTripsCell = ({ rowData, dataKey, ...props }) => (<Cell {...props}>{rowData.previous_trips ? 'نعم يوجد رحلة' : "لا يوجد رحلة"}</Cell>);
    const CountCell = ({ rowData, dataKey, rowIndex, ...props }) => (
        <Cell {...props}>
            <Link
                style={{ color: '#000' }}
                to={`${path}item/id/${rowData.id}/name/${rowData.name}`}
            >
                {(((page ? page : 1) - 1) * 100) + (rowIndex + 1)}
            </Link>
        </Cell>
    );
    const ChildrenAgesCell = ({ rowData, dataKey, ...props }) => (<Cell {...props}>{rowData.children_ages.map((i, n) => `عمر الطفل (${n + 1}) : ${i} سنوات ,`)}</Cell>);
    const renderLoading = () => {
        return (
            <div className=' d-flex justify-content-center align-items-start w-100 mt-5'>
                <Spin size="large" tip="جاري التحميل" />
            </div>

        );
    };

    return (
        <Table
            //loading={loading}
            virtualized
            height={720}
            width='100%'
            data={data}
            rtl={true}
            renderEmpty={() => {
                return <div className="rs-table-body-info">لاتوجد بيانات</div>;
            }}
            renderLoading={renderLoading}
            style={{ backgroundColor: '#fff' }}
        >
            <Column width={70} align="center" fixed>
                <HeaderCell>ت</HeaderCell>
                <CountCell dataKey="id" />
            </Column>
            <Column align='center' width={200} fullText fixed resizable>
                <HeaderCell>الإسم</HeaderCell>
                <NameCell dataKey="name" />
            </Column>
            <Column align='center' width={200} fullText resizable>
                <HeaderCell>الموسم</HeaderCell>
                <Cell dataKey="season" />
            </Column>
            <Column align='center' width={200} fullText resizable>
                <HeaderCell>الرحلة</HeaderCell>
                <Cell dataKey="trips" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>المحافضة</HeaderCell>
                <Cell dataKey="city" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>العمر</HeaderCell>
                <Cell dataKey="age" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>التحصيل الدراسي</HeaderCell>
                <Cell dataKey="education" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>رقم الهاتف</HeaderCell>
                <Cell dataKey="phone" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>رابط الاستمارة</HeaderCell>
                <Cell dataKey="survey_link" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell> الرحلات السابقة</HeaderCell>
                <PreviousTripsCell dataKey="previous_trips" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>الأطفال</HeaderCell>
                <HasChildrenCell dataKey="has_children" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>عدد الأطفال</HeaderCell>
                <ChildrenCountCell dataKey="children_count" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>اعمار الأطفال</HeaderCell>
                <ChildrenAgesCell dataKey="children_ages" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>تاريخ التقديم</HeaderCell>
                <DateCell dataKey="createdAt" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>حالة الحجز</HeaderCell>
                <StatusCell dataKey="is_active" />
            </Column>
        </Table>
    )
}



