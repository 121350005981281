import { popUb } from "../../var_func/var_func"

export const list = [
    {
        name: 'قائمة حجوزات رحلة أمرلي',
        type: 'link',
        path: '/emirli/list/',
        color: "#009FBD",
        icon: 'fa-solid fa-list-alt',
    },
    {
        name: 'قائمة حجوزات رحلة بيجي',
        type: 'link',
        path: '/baiji/list/',
        color: "#009FBD",
        icon: 'fa-solid fa-list-alt',
    },
    {
        name: 'تقييم الرحلات',
        path: '/accounts/home/',
        color: "#19A7CE",
        icon: 'fas fa-chart-simple',
        key: 33,
        content: [
            {
                name: 'قائمة التقييمات',
                type: 'link',
                path: '/reviews/list/',
                color: "#009FBD",
                icon: 'fa-solid fa-list-alt',
            },
            {
                name: 'قائمة الملاحظات',
                type: 'link',
                path: '/reviews/notes/',
                color: "#009FBD",
                icon: 'fa-solid fa-list-alt',
            },
            {
                name: 'تقارير التقييمات',
                type: 'link',
                path: '/reviews/report/',
                color: "#009FBD",
                icon: 'fa-solid fa-chart-bar',
            },

        ]
    },
]

export const list2 = [
    {
        name: 'الأخبار والنشاطات',
        type: 'link',
        path: '#',
        onclick: () => popUb(),
        color: "#009FBD",
        icon: 'fa-solid fa-rss'
    },
    {
        name: 'الأرشيف الصوري',
        type: 'link',
        path: '#',
        onclick: () => popUb(),
        color: "#009FBD",
        icon: 'fa-solid fa-images'
    },
    {
        name: 'الأرشيف المرئي',
        type: 'link',
        path: '#',
        onclick: () => popUb(),
        color: "#009FBD",
        icon: 'fa-solid fa-video'
    },
    {
        name: 'مواسم معراج',
        type: 'link',
        path: '#',
        onclick: () => popUb(),
        color: "#009FBD",
        icon: 'fa-solid fa-list'
    },
]

export const listUsers = [
    {
        name: 'المستخدمين',
        path: '/accounts/home/',
        color: "#19A7CE",
        icon: 'fas fa-users',
        key: 29,
        content: [
            {
                name: 'إضافة مستخدم',
                type: 'link',
                path: '/accounts/add/',
                color: "#19A7CE",
                icon: 'fa-solid fa-plus',
            },
            {
                name: 'قائمة المستخدمين',
                type: 'link',
                path: '/accounts/list/',
                color: "#19A7CE",
                icon: 'fa-solid fa-list-alt',
            },

        ]
    },
    {
        name: 'الأعدادات',
        type: 'link',
        path: '/settings/',
        color: "#19A7CE",
        icon: 'fas fa-cog',
    },
]

