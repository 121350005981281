import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import Chart from "chart.js";
import {
    chartOptions,
    parseOptions,
    chartExample3
} from './chartOptions';

export default function DoughnutChart({ labels, data }) {
    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }
    return (
        <div className='chart m-2'>
            <Doughnut
                data={chartExample3.data(labels, data)}
                width={250}
                options={{responsive:true}}
            />
        </div>

    )
}