export const customDataReviews = (data) => {
    const Data = data.map(item => ({
        "الموسم": item.season,
        "الرحلة": item.trips,
        "رقم الباص": item.bus,
        "الجنس": item.sex,
        "العمر": item.age,
        "رحلات الموسم الاول": item.first_season,
        "رحلات الموسم الثاني": item.second_season,
        "المجموعات الخاصة بالمشتركين": item.group_add ? "نعم" : "كلا",
        "طالب جامعي": item.college_student ? "نعم" : "كلا",
        "عضوية موكب أو هيئة حسينية ": item.parade_member ? "نعم" : "كلا",
        "الاستمارة الألكترونية": item.form_fill ? "نعم" : "كلا",
        "سلاسة التسجيل في الرحلة": item.registration_form,
        "مواعيد الاستعداد والالتحاق بسيارات النقل": item.appointments,
        "الراحة في سيارات النقل": item.comfort,
        "وضوح إرشادات الكادر في السيارات": item.instructions,
        "عمل المتطوعين": item.volunteers,
        "المحطة الاولى (المقام)": item.first_station,
        "المحطة الثانية (المعلم)": item.second_station,
        "المحطة الثالثة (النخلة)  براوجلي (الحجية)": item.third_station,
        "المحطة الرابعة (التل)": item.fourth_station,
        "المحطة الخامسة (الساتر)": item.fifth_station,
        "المحطة السادسة (النصب) (الشمسية)": item.seventh_station,
        "وضوح الرواة بالطرح والكلام": item.speech,
        "جودة وجبات الطعام": item.food,
        "راحة مكان المبيت": item.overnight,
        "جودة الإضاءة": item.lighting,
        "جودة الصوتيات": item.acoustics,
        "جودة تعامل الكادر": item.team,
        "منصة معراج على الفيسبوك": item.facebook,
        "منصة معراج على الانستكرام": item.instagram,
        "منصة معراج على التلكرام": item.telegram,
        "ملاحظات أو مقترحات": item.notes,
    })
    )
    return Data
}
