import React from 'react'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
export function TableReviews({ data, page, type }) {
    const path = '/reviews/list/';
    const NameCell = ({ rowData, dataKey, ...props }) => (
        <Cell {...props}>
            <Link
                style={{ color: '#000' }}
                to={`${path}item/id/${rowData.id}/`}
            >
                {rowData.trips}
            </Link>
        </Cell>
    );

    const DateCell = ({ rowData, dataKey, ...props }) => (<Cell dir='ltr' {...props}>{moment(rowData.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Cell>);
    const PreviousCell = ({ rowData, dataKey, ...props }) => (<Cell {...props}>{rowData[dataKey] ? 'نعم ' : "كلا"}</Cell>);
    const CountCell = ({ rowData, dataKey, rowIndex, ...props }) => (
        <Cell {...props}>
            <Link
                style={{ color: '#000' }}
                to={`${path}item/id/${rowData.id}/`}
            >
                {(((page ? page : 1) - 1) * 100) + (rowIndex + 1)}
            </Link>
        </Cell>
    );
    const ChildrenAgesCell = ({ rowData, dataKey, ...props }) => (<Cell {...props}>{rowData.children_ages.map((i, n) => `عمر الطفل (${n + 1}) : ${i} سنوات ,`)}</Cell>);
    const renderLoading = () => {
        return (
            <div className=' d-flex justify-content-center align-items-start w-100 mt-5'>
                <Spin size="large" tip="جاري التحميل" />
            </div>
        );
    };

    return (
        <Table
            //loading={loading}
            virtualized
            height={720}
            width='100%'
            data={data}
            rtl={true}
            renderEmpty={() => {
                return <div className="rs-table-body-info">لاتوجد بيانات</div>;
            }}
            renderLoading={renderLoading}
            style={{ backgroundColor: '#fff' }}
        >
            <Column width={70} align="center" fixed>
                <HeaderCell>ت</HeaderCell>
                <CountCell dataKey="id" />
            </Column>
            <Column align='center' width={200} fullText fixed resizable>
                <HeaderCell>الرحلة</HeaderCell>
                <NameCell dataKey="trips" />
            </Column>
            <Column align='center' width={200} fullText resizable>
                <HeaderCell>الموسم</HeaderCell>
                <Cell dataKey="season" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>الباص</HeaderCell>
                <Cell dataKey="bus" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>العمر</HeaderCell>
                <Cell dataKey="age" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>الجنس</HeaderCell>
                <Cell dataKey="sex" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>الموسم الأول</HeaderCell>
                <Cell dataKey="first_season" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>الموسم الثاني</HeaderCell>
                <Cell dataKey="second_season" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>المجموعات الخاصة</HeaderCell>
                <PreviousCell dataKey="group_add" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>طالب جامعي</HeaderCell>
                <PreviousCell dataKey="college_student" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>عضو موكب او هيئة</HeaderCell>
                <PreviousCell dataKey="parade_member" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>الاستمارة الألكترونية</HeaderCell>
                <PreviousCell dataKey="form_fill" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>سلاسة التسجيل في الرحلة</HeaderCell>
                <Cell dataKey="registration_form" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>مواعيد الاستعداد والالتحاق بسيارات النقل</HeaderCell>
                <Cell dataKey="appointments" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>الراحة في سيارات النقل</HeaderCell>
                <Cell dataKey="comfort" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>وضوح إرشادات الكادر في السيارات</HeaderCell>
                <Cell dataKey="instructions" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>عمل المتطوعين</HeaderCell>
                <Cell dataKey="volunteers" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>المحطة الاولى</HeaderCell>
                <Cell dataKey="first_station" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>المحطة الثانية</HeaderCell>
                <Cell dataKey="second_station" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>المحطة الثالثة</HeaderCell>
                <Cell dataKey="third_station" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>المحطة الرابعة</HeaderCell>
                <Cell dataKey="fourth_station" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>المحطة الخامسة</HeaderCell>
                <Cell dataKey="fifth_station" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>المحطة السادسة</HeaderCell>
                <Cell dataKey="seventh_station" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>وضوح الرواة بالطرح والكلام</HeaderCell>
                <Cell dataKey="speech" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>جودة وجبات الطعام</HeaderCell>
                <Cell dataKey="food" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>راحة مكان المبيت</HeaderCell>
                <Cell dataKey="overnight" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>جودة الإضاءة</HeaderCell>
                <Cell dataKey="lighting" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>جودة الصوتيات</HeaderCell>
                <Cell dataKey="acoustics" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>جودة تعامل الكادر</HeaderCell>
                <Cell dataKey="team" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>منصة معراج على الفيسبوك</HeaderCell>
                <Cell dataKey="facebook" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>منصة معراج على الانستكرام</HeaderCell>
                <Cell dataKey="instagram" />
            </Column>
            <Column width={100} align='center' fullText resizable>
                <HeaderCell>منصة معراج على التلكرام</HeaderCell>
                <Cell dataKey="telegram" />
            </Column>
            <Column width={300} align='center' fullText resizable>
                <HeaderCell>ملاحظات أو مقترحات </HeaderCell>
                <Cell dataKey="notes" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>تاريخ التقييم</HeaderCell>
                <DateCell dataKey="createdAt" />
            </Column>
        </Table>
    )
}
