import { Avatar, Drawer } from 'antd';
import React, { useRef, useState } from 'react'
import { CustomInput, CustomSelect } from '../forms/CustomForms';
import axiosInstance from '../../api/axiosInstance';
import { useEffect } from 'react';
import { Button, Offcanvas, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { defaultsImage, downlodXlsx } from '../../var_func/var_func';
import { useCookies } from 'react-cookie';
import { PaginationPage } from '../global';
import { ReportPop } from '../forms/PopForm';
import { customDataReviews } from '../../pages/reviews/data';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

export function SortReviews(prpos) {
    const { onClose, open, type } = prpos;
    const [page, setPage] = useCookies('');
    const { sort_Reviews_list } = page;
    const [sortValues, setSortValues] = useState({
        trips: '',
        season: '',
        sex: '',
        age: '',
        bus: '',
        first_season: '',
        second_season: '',
        group_add: '',
        college_student: '',
        parade_member: '',
        form_fill: '',
        registration_form: '',
        appointments: '',
        comfort: '',
        instructions: '',
        volunteers: '',
        first_station: '',
        second_station: '',
        third_station: '',
        fourth_station: '',
        fifth_station: '',
        seventh_station: '',
        speech: '',
        food: '',
        overnight: '',
        lighting: '',
        acoustics: '',
        team: '',
        facebook: '',
        instagram: '',
        telegram: '',
    })
    const [SortData, setSortData] = useState({
        managements: [],
        list: [],
        listReport: [],
        listCount: 0
    })
    const [formSearch, setFormSearch] = useState({
        managements: '',
    })
    const [pop, setPop] = useState({
        one: false,
        two: false,
        report: false,
    });
    const ondrawer = (name, value) => {
        setPop((state) => {
            return { ...state, [name]: value }
        })
    }
    const [drawer, setDrawer] = useState(false);
    const change = (name, value) => {
        setSortValues((state) => {
            return { ...state, [name]: value }
        })
    }
    const getFormData = (name, value) => {
        setSortData((state) => {
            return { ...state, [name]: value }
        })
    }
    const searchForm = (name, value) => {
        setFormSearch((state) => {
            return { ...state, [name]: value }
        })
    }
    const showChildrenDrawer = () => {
        setDrawer(true);
    };

    const onChildrenDrawerClose = () => {
        setDrawer(false);
    };
    const path = '/reviews/list/';
    const url = '/reviews/api/v1/survey/';
    const title = 'قائمة التقييمات';
    useEffect(() => {
        //get death
        async function getData() {
            const sort = `season=${sortValues.season}&trips=${sortValues.trips}&bus=${sortValues.bus}&sex=${sortValues.sex}&age=${sortValues.age}&first_season=${sortValues.first_season}&second_season=${sortValues.second_season}`;
            const sort2 = `group_add=${sortValues.group_add}&college_student=${sortValues.college_student}&parade_member=${sortValues.parade_member}&form_fill=${sortValues.form_fill}&registration_form=${sortValues.registration_form}&appointments=${sortValues.appointments}`;
            const sort3 = `comfort=${sortValues.comfort}&instructions=${sortValues.instructions}&volunteers=${sortValues.volunteers}&first_station=${sortValues.first_station}&second_station=${sortValues.second_station}&third_station=${sortValues.third_station}&fourth_station=${sortValues.fourth_station}&fifth_station=${sortValues.fifth_station}`;
            const sort4 = `seventh_station=${sortValues.seventh_station}&speech=${sortValues.speech}&food=${sortValues.food}&overnight=${sortValues.overnight}&lighting=${sortValues.lighting}&acoustics=${sortValues.acoustics}`;
            const sort5 = `team=${sortValues.team}&facebook=${sortValues.facebook}&instagram=${sortValues.instagram}&telegram=${sortValues.telegram}`;
            if (drawer) {
                await axiosInstance.get(`${url}?${sort}&${sort2}&${sort3}&${sort4}&${sort5}&page_size=100&page=${sort_Reviews_list ? sort_Reviews_list : 1}`)
                    .then(res => {
                        getFormData('list', res.data.results)
                        getFormData('listCount', res.data.count)
                        if (res.data.count > 0 && res.data.results.length === 0) setPage('sort_Reviews_list', 1)
                    }).catch(err => {
                        if (err.response.status === 304) {
                            setPage('sort_Reviews_list', 1)
                        }
                    })
            }
        }
        getData()
    }, [sortValues, drawer, sort_Reviews_list])
    useEffect(() => {
        //get death
        async function getData() {
            if (pop.report) {
                const sort = `season=${sortValues.season}&trips=${sortValues.trips}&bus=${sortValues.bus}&sex=${sortValues.sex}&age=${sortValues.age}&first_season=${sortValues.first_season}&second_season=${sortValues.second_season}`;
                const sort2 = `group_add=${sortValues.group_add}&college_student=${sortValues.college_student}&parade_member=${sortValues.parade_member}&form_fill=${sortValues.form_fill}&registration_form=${sortValues.registration_form}&appointments=${sortValues.appointments}`;
                const sort3 = `comfort=${sortValues.comfort}&instructions=${sortValues.instructions}&volunteers=${sortValues.volunteers}&third_station=${sortValues.third_station}&fourth_station=${sortValues.fourth_station}&fifth_station=${sortValues.fifth_station}`;
                const sort4 = `seventh_station=${sortValues.seventh_station}&speech=${sortValues.speech}&food=${sortValues.food}&overnight=${sortValues.overnight}&lighting=${sortValues.lighting}&acoustics=${sortValues.acoustics}`;
                const sort5 = `team=${sortValues.team}&facebook=${sortValues.facebook}&instagram=${sortValues.instagram}&telegram=${sortValues.telegram}`;
                if (drawer) {
                    await axiosInstance.get(`${url}?${sort}&${sort2}&${sort3}&${sort4}&${sort5}&page_size=${SortData.listCount}`)
                        .then(res => {
                            getFormData('listReport', res.data.results)
                        }).catch(err => {
                            ondrawer('report', false)
                        })
                }
            }
        }
        getData()
    }, [sortValues, drawer, sort_Reviews_list, pop.report])

    const part = ["الرحلة الاولى", "الرحلة الثانية", "الرحلة الثالثة", "الرحلة الرابعة", "الرحلة الخامسة", "الرحلة السادسة", "الرحلة السابعة", "الرحلة الثامنة"];
    const status = ['جيد', "متوسط", "ضعيف"]
    const first = ['الرحلة الاولى', 'الرحلة الثالثة', 'الرحلة الرابعة', 'لم اشارك']
    const second = ['الرحلة الاولى', 'الرحلة الثانية', 'الرحلة الثالثة', 'الرحلة الرابعة', 'الرحلة الخامسة', 'الرحلة السادسة', 'الرحلة السابعة', 'لم اشارك']
    const seasons = [
        { key: 3, name: 'الثالث' },
        { key: 4, name: 'الرابع' },
        { key: 5, name: 'الخامس' },
    ]
    const onButtonReport = () => {
        downlodXlsx(customDataReviews(SortData.listReport), 'تقرير ' + title + Math.floor(Math.random() * 100000))
    }
    return (
        <>
            <Offcanvas show={open} onHide={onClose} className='offcanvas-edit'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>فرز {title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div class="container-fluid">
                        <div className='row justify-content-center g-2'>
                            <div className='col-12'>
                                <label>الموسم</label>
                                <CustomSelect
                                    icon='date_range'
                                    name='season'
                                    onChange={(e) => change('season', e === undefined ? '' : e)}
                                    onClear={() => change('season', '')}
                                >
                                    {seasons.map(item => <option value={item.key}>{item.name}</option>)}
                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>الرحلة</label>
                                <CustomSelect
                                    icon='date_range'
                                    name='trips'
                                    onChange={(e) => change('trips', e === undefined ? '' : e)}
                                    onClear={() => change('trips', '')}
                                >
                                    {part.map(item => <option value={item}>{item}</option>)}
                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label >الباص</label>
                                <CustomInput type="number" icon='airport_shuttle' name='bus' min={0} max={150} placeholder="الباص" onChange={(e) => change('bus', e.target.value)} />
                            </div>
                            <div className='col-12'>
                                <label>العمر</label>
                                <CustomInput type="number" icon='123' name='age' min={0} max={150} placeholder="العمر" onChange={(e) => change(e.target.name, e.target.value)} />
                            </div>
                            <div className='col-12'>
                                <label>الجنس</label>
                                <CustomSelect
                                    icon='wc'
                                    name='sex'
                                    onChange={(e) => change('sex', e === undefined ? '' : e)}
                                    onClear={() => change('sex', '')}
                                >
                                    <option value='ذكر'>ذكر</option>
                                    <option value='انثى'>انثى</option>

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>المجموعات الخاصة بالمشتركين</label>
                                <CustomSelect
                                    icon='sort'
                                    name='group_add'
                                    onChange={(e) => change('group_add', e === undefined ? '' : e)}
                                    onClear={() => change('group_add', '')}
                                >
                                    <option value={true}>نعم</option>
                                    <option value={false}>كلا</option>

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>رحلات الموسم الاول</label>
                                <CustomSelect
                                    icon='sort'
                                    name='first_season'
                                    onChange={(e) => change('first_season', e === undefined ? '' : e)}
                                    onClear={() => change('first_season', '')}
                                >
                                    {first.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label> رحلات الموسم الثاني</label>
                                <CustomSelect
                                    icon='sort'
                                    name='second_season'
                                    onChange={(e) => change('second_season', e === undefined ? '' : e)}
                                    onClear={() => change('second_season', '')}
                                >
                                    {second.map(item => <option value={item}>{item}</option>)}
                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>طالب جامعي</label>
                                <CustomSelect
                                    icon='sort'
                                    name='college_student'
                                    onChange={(e) => change('college_student', e === undefined ? '' : e)}
                                    onClear={() => change('college_student', '')}
                                >
                                    <option value={true}>نعم</option>
                                    <option value={false}>كلا</option>

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>عضوية موكب أو هيئة حسينية</label>
                                <CustomSelect
                                    icon='sort'
                                    name='parade_member'
                                    onChange={(e) => change('parade_member', e === undefined ? '' : e)}
                                    onClear={() => change('parade_member', '')}
                                >
                                    <option value={true}>نعم</option>
                                    <option value={false}>كلا</option>

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>الاستمارة الألكترونية</label>
                                <CustomSelect
                                    icon='sort'
                                    name='form_fill'
                                    onChange={(e) => change('form_fill', e === undefined ? '' : e)}
                                    onClear={() => change('form_fill', '')}
                                >
                                    <option value={true}>نعم</option>
                                    <option value={false}>كلا</option>

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>سلاسة التسجيل في الرحلة</label>
                                <CustomSelect
                                    icon='sort'
                                    name='registration_form'
                                    onChange={(e) => change('registration_form', e === undefined ? '' : e)}
                                    onClear={() => change('registration_form', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>مواعيد الاستعداد والالتحاق بسيارات النقل</label>
                                <CustomSelect
                                    icon='sort'
                                    name='appointments'
                                    onChange={(e) => change('appointments', e === undefined ? '' : e)}
                                    onClear={() => change('appointments', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>الراحة في سيارات النقل</label>
                                <CustomSelect
                                    icon='sort'
                                    name='comfort'
                                    onChange={(e) => change('comfort', e === undefined ? '' : e)}
                                    onClear={() => change('comfort', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>وضوح إرشادات الكادر في السيارات</label>
                                <CustomSelect
                                    icon='sort'
                                    name='instructions'
                                    onChange={(e) => change('instructions', e === undefined ? '' : e)}
                                    onClear={() => change('instructions', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>عمل المتطوعين</label>
                                <CustomSelect
                                    icon='sort'
                                    name='volunteers'
                                    onChange={(e) => change('volunteers', e === undefined ? '' : e)}
                                    onClear={() => change('volunteers', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>المحطة الاولى</label>
                                <CustomSelect
                                    icon='sort'
                                    name='first_station'
                                    onChange={(e) => change('first_station', e === undefined ? '' : e)}
                                    onClear={() => change('first_station', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>المحطة الثانية</label>
                                <CustomSelect
                                    icon='sort'
                                    name='second_station'
                                    onChange={(e) => change('second_station', e === undefined ? '' : e)}
                                    onClear={() => change('second_station', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>المحطة الثالثة</label>
                                <CustomSelect
                                    icon='sort'
                                    name='third_station'
                                    onChange={(e) => change('third_station', e === undefined ? '' : e)}
                                    onClear={() => change('third_station', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>المحطة الرابعة</label>
                                <CustomSelect
                                    icon='sort'
                                    name='fourth_station'
                                    onChange={(e) => change('fourth_station', e === undefined ? '' : e)}
                                    onClear={() => change('fourth_station', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>المحطة الخامسة</label>
                                <CustomSelect
                                    icon='sort'
                                    name='fifth_station'
                                    onChange={(e) => change('fifth_station', e === undefined ? '' : e)}
                                    onClear={() => change('fifth_station', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}
                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>المحطة السادسة</label>
                                <CustomSelect
                                    icon='sort'
                                    name='seventh_station'
                                    onChange={(e) => change('seventh_station', e === undefined ? '' : e)}
                                    onClear={() => change('seventh_station', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>وضوح الرواة بالطرح والكلام</label>
                                <CustomSelect
                                    icon='sort'
                                    name='speech'
                                    onChange={(e) => change('speech', e === undefined ? '' : e)}
                                    onClear={() => change('speech', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>جودة وجبات الطعام</label>
                                <CustomSelect
                                    icon='sort'
                                    name='food'
                                    onChange={(e) => change('food', e === undefined ? '' : e)}
                                    onClear={() => change('food', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>راحة مكان المبيت</label>
                                <CustomSelect
                                    icon='sort'
                                    name='overnight'
                                    onChange={(e) => change('overnight', e === undefined ? '' : e)}
                                    onClear={() => change('overnight', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>جودة الإضاءة</label>
                                <CustomSelect
                                    icon='sort'
                                    name='lighting'
                                    onChange={(e) => change('lighting', e === undefined ? '' : e)}
                                    onClear={() => change('lighting', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>جودة الصوتيات</label>
                                <CustomSelect
                                    icon='sort'
                                    name='acoustics'
                                    onChange={(e) => change('acoustics', e === undefined ? '' : e)}
                                    onClear={() => change('acoustics', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>جودة تعامل الكادر</label>
                                <CustomSelect
                                    icon='sort'
                                    name='team'
                                    onChange={(e) => change('team', e === undefined ? '' : e)}
                                    onClear={() => change('team', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>منصة معراج على الفيسبوك</label>
                                <CustomSelect
                                    icon='sort'
                                    name='facebook'
                                    onChange={(e) => change('facebook', e === undefined ? '' : e)}
                                    onClear={() => change('facebook', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>منصة معراج على الانستكرام</label>
                                <CustomSelect
                                    icon='sort'
                                    name='instagram'
                                    onChange={(e) => change('instagram', e === undefined ? '' : e)}
                                    onClear={() => change('instagram', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}

                                </CustomSelect>
                            </div>
                            <div className='col-12'>
                                <label>منصة معراج على التلكرام</label>
                                <CustomSelect
                                    icon='sort'
                                    name='telegram'
                                    onChange={(e) => change('telegram', e === undefined ? '' : e)}
                                    onClear={() => change('telegram', '')}
                                >
                                    {status.map(item => <option value={item}>{item}</option>)}
                                </CustomSelect>
                            </div>

                        </div>
                        <div className='row justify-content-center g-2 mt-5 '>
                            <Button variant="" className='but-all w-100' onClick={showChildrenDrawer}>
                                معاينة النتائج
                            </Button>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas show={drawer} onHide={onChildrenDrawerClose} className='offcanvas-edit'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>نتائج الفرز</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {SortData.listCount > 0 ?
                        <>
                            <p>عدد النتائج : {SortData.listCount}</p>
                            <div className='table-responsive'>
                                <Table className="align-items-center table-flush">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>ت</th>
                                            <th scope="col">الرحلة</th>
                                            <th scope="col">الباص</th>
                                            <th scope="col">الجنس</th>
                                            <th scope="col">العمر</th>
                                            <th scope="col">الموسم الأول</th>
                                            <th scope="col">الموسم الثاني</th>
                                            <th scope="col">المجموعات الخاصة</th>
                                            <th scope="col">طالب جامعي</th>
                                            <th scope="col">عضو موكب او هيئة</th>
                                            <th scope="col">الاستمارة الألكترونية</th>
                                            <th scope="col">سلاسة التسجيل في الرحلة</th>
                                            <th scope="col">مواعيد الاستعداد والالتحاق بسيارات النقل</th>
                                            <th scope="col">الراحة في سيارات النقل</th>
                                            <th scope="col">وضوح إرشادات الكادر في السيارات</th>
                                            <th scope="col">عمل المتطوعين</th>
                                            <th scope="col">المحطة الثالثة ( معلم )</th>
                                            <th scope="col">المحطة الرابعة (النخلة )</th>
                                            <th scope="col">المحطة الخامسة ( التل )</th>
                                            <th scope="col">المحطة السابعة ( الشمسية )</th>
                                            <th scope="col">وضوح الرواة بالطرح والكلام</th>
                                            <th scope="col">جودة وجبات الطعام</th>
                                            <th scope="col">راحة مكان المبيت</th>
                                            <th scope="col">جودة الإضاءة</th>
                                            <th scope="col">جودة الصوتيات</th>
                                            <th scope="col">جودة تعامل الكادر</th>
                                            <th scope="col">منصة معراج على الفيسبوك</th>
                                            <th scope="col">منصة معراج على الانستكرام</th>
                                            <th scope="col">منصة معراج على التلكرام</th>
                                            <th scope="col">ملاحظات أو مقترحات</th>
                                            <th scope="col">تاريخ التقييم</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {SortData.list.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{(((sort_Reviews_list ? sort_Reviews_list : 1) - 1) * 100) + (index + 1)}</td>
                                                    <th scope="row">
                                                        <Link to={`${path}item/id/${item.id}/`}>
                                                            {item.trips}
                                                        </Link>
                                                    </th>
                                                    <td>{item.bus}</td>
                                                    <td>{item.sex}</td>
                                                    <td>{item.age}</td>
                                                    <td>{item.first_season}</td>
                                                    <td>{item.second_season}</td>
                                                    <td>{item.group_add ? "نعم" : "كلا"}</td>
                                                    <td>{item.college_student ? "نعم" : "كلا"}</td>
                                                    <td>{item.parade_member ? "نعم" : "كلا"}</td>
                                                    <td>{item.form_fill ? "نعم" : "كلا"}</td>
                                                    <td>{item.registration_form}</td>
                                                    <td>{item.appointments}</td>
                                                    <td>{item.comfort}</td>
                                                    <td>{item.instructions}</td>
                                                    <td>{item.volunteers}</td>
                                                    <td>{item.third_station}</td>
                                                    <td>{item.fourth_station}</td>
                                                    <td>{item.fifth_station}</td>
                                                    <td>{item.seventh_station}</td>
                                                    <td>{item.speech}</td>
                                                    <td>{item.food}</td>
                                                    <td>{item.overnight}</td>
                                                    <td>{item.lighting}</td>
                                                    <td>{item.acoustics}</td>
                                                    <td>{item.team}</td>
                                                    <td>{item.facebook}</td>
                                                    <td>{item.instagram}</td>
                                                    <td>{item.telegram}</td>
                                                    <td>{item.notes}</td>
                                                    <td dir='ltr'>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </>
                        :
                        <div className='d-flex flex-column justify-content-center align-items-center py-5'>
                            <i class="fa-solid fa-table" style={{ fontSize: 75, color: '#aaaa' }}></i>
                            <p className='py-2' style={{ fontSize: 25, color: '#aaaa' }}>لا توجد بيانات</p>
                        </div>
                    }


                    <div className="col-12">
                        <PaginationPage
                            defaultCurrent={!sort_Reviews_list ? 1 : Number(sort_Reviews_list)}
                            total={SortData.listCount}
                            defaultPageSize={100}
                            onChange={(e) => setPage('sort_Reviews_list', e === undefined ? '' : e)}
                        />
                    </div>
                    {SortData.listCount > 0 &&
                        <div className='row justify-content-center g-2 mt-5 '>
                            <Button variant="" className='but-all w-100' onClick={() => ondrawer('report', true)}>
                                تحميل البيانات
                            </Button>
                        </div>
                    }
                </Offcanvas.Body>
            </Offcanvas>
            <ReportPop
                open={pop.report}
                title={title}
                onClose={() => ondrawer('report', false)}
                onOK={onButtonReport}
            />
        </>
    )
}

export function SortReviewsNotes(prpos) {
    const targetRef = useRef();
    const { onClose, open, type } = prpos;
    const [page, setPage] = useCookies('');
    const { sort_Reviews_list_notes } = page;
    const [sortValues, setSortValues] = useState({
        trips: '',

    })
    const [SortData, setSortData] = useState({
        managements: [],
        list: [],
        listReport: [],
        listCount: 0
    })
    const [formSearch, setFormSearch] = useState({
        managements: '',
    })
    const [pop, setPop] = useState({
        one: false,
        two: false,
        report: false,
    });
    const ondrawer = (name, value) => {
        setPop((state) => {
            return { ...state, [name]: value }
        })
    }
    const [drawer, setDrawer] = useState(false);
    const change = (name, value) => {
        setSortValues((state) => {
            return { ...state, [name]: value }
        })
    }
    const getFormData = (name, value) => {
        setSortData((state) => {
            return { ...state, [name]: value }
        })
    }
    const searchForm = (name, value) => {
        setFormSearch((state) => {
            return { ...state, [name]: value }
        })
    }
    const showChildrenDrawer = () => {
        setDrawer(true);
    };

    const onChildrenDrawerClose = () => {
        setDrawer(false);
    };
    const path = '/reviews/list/';
    const url = '/reviews/api/v1/survey/';
    const title = 'قائمة التقييمات';
    useEffect(() => {
        //get death
        async function getData() {
            const sort = `trips=${sortValues.trips}`;
            if (drawer) {
                await axiosInstance.get(`${url}?${sort}&page_size=100&page=${sort_Reviews_list_notes ? sort_Reviews_list_notes : 1}`)
                    .then(res => {
                        getFormData('list', res.data.results)
                        getFormData('listCount', res.data.count)
                        if (res.data.count > 0 && res.data.results.length === 0) setPage('sort_Reviews_list_notes', 1)
                    }).catch(err => {
                        if (err.response.status === 304) {
                            setPage('sort_Reviews_list_notes', 1)
                        }
                    })
            }
        }
        getData()
    }, [sortValues, drawer, sort_Reviews_list_notes])
    useEffect(() => {
        //get death
        async function getData() {
            if (pop.report) {
                const sort = `trips=${sortValues.trips}`;
                if (drawer) {
                    await axiosInstance.get(`${url}?${sort}&page_size=${SortData.listCount}`)
                        .then(res => {
                            getFormData('listReport', res.data.results)
                        }).catch(err => {
                            ondrawer('report', false)
                        })
                }
            }
        }
        getData()
    }, [sortValues, drawer, sort_Reviews_list_notes, pop.report])

    const part = ["الرحلة الاولى", "الرحلة الثانية", "الرحلة الثالثة", "الرحلة الرابعة", "الرحلة الخامسة", "الرحلة السادسة", "الرحلة السابعة", "الرحلة الثامنة"];
    const status = ['جيد', "متوسط", "ضعيف"]
    const first = ['الرحلة الاولى', 'الرحلة الثالثة', 'الرحلة الرابعة', 'لم اشارك']
    const second = ['الرحلة الاولى', 'الرحلة الثانية', 'الرحلة الثالثة', 'الرحلة الرابعة', 'الرحلة الخامسة', 'الرحلة السادسة', 'الرحلة السابعة', 'لم اشارك']
    const onButtonReport = () => {
        downlodXlsx(customDataReviews(SortData.listReport), 'تقرير ' + title + Math.floor(Math.random() * 100000))
    }
    const Header = () => (
        <>
            <img src={require('../../styles/images/dashboard_img/logo.png')} alt="" class="watermark" />
            <div class="page-footer m-0 p-0">
                <div class='center'>
                    <p>المديرية العامة
                        <br />للعلاقات والخدمات الأجتماعية</p>
                </div>
                <div class='center text-center'>
                    <p className='m-0 p-0'>تم انشاء التقرير
                        بأستخدام نظام بيان
                        <p className='fs-10'>قسم تقنية المعلومات والأتصالات</p>
                    </p>
                </div>
                <div class='center'>
                    <p>التاريخ : {moment().format('YYYY-MM-DD hh:mm:ss')}</p>
                </div>
            </div>
        </>
    )
    return (
        <>
            <Offcanvas show={open} onHide={onClose} className='offcanvas-edit'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>فرز {title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div class="container-fluid">
                        <div className='row justify-content-center g-2'>

                            <div className='col-12'>
                                <label>الرحلة</label>
                                <CustomSelect
                                    icon='date_range'
                                    name='trips'
                                    onChange={(e) => change('trips', e === undefined ? '' : e)}
                                    onClear={() => change('trips', '')}
                                >
                                    {part.map(item => <option value={item}>{item}</option>)}
                                </CustomSelect>
                            </div>
                        </div>
                        <div className='row justify-content-center g-2 mt-5 '>
                            <Button variant="" className='but-all w-100' onClick={showChildrenDrawer}>
                                معاينة النتائج
                            </Button>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas show={drawer} onHide={onChildrenDrawerClose} className='offcanvas-edit'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>نتائج الفرز</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {SortData.listCount > 0 ?
                        <>
                            <p>عدد النتائج : {SortData.listCount}</p>
                            <div className='table-responsive' ref={targetRef}>
                                <Header />
                                {SortData.list.map((item, index) => {
                                    return (
                                        <div className='notes'>
                                            <p className='tag'>{item.trips}</p>
                                            <p className='title'>الملاحظة  {(((sort_Reviews_list_notes ? sort_Reviews_list_notes : 1) - 1) * 100) + (index + 1)}</p>
                                            <p className='text'>
                                                {item.notes.split('\n\n').map((text, index) => {
                                                    return (
                                                        <p key={index + 1}>{text}</p>
                                                    )
                                                })}
                                            </p>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                        :
                        <div className='d-flex flex-column justify-content-center align-items-center py-5'>
                            <i class="fa-solid fa-table" style={{ fontSize: 75, color: '#aaaa' }}></i>
                            <p className='py-2' style={{ fontSize: 25, color: '#aaaa' }}>لا توجد بيانات</p>
                        </div>
                    }


                    <div className="col-12">
                        <PaginationPage
                            defaultCurrent={!sort_Reviews_list_notes ? 1 : Number(sort_Reviews_list_notes)}
                            total={SortData.listCount}
                            defaultPageSize={100}
                            onChange={(e) => setPage('sort_Reviews_list_notes', e === undefined ? '' : e)}
                        />
                    </div>
                    {SortData.listCount > 0 &&

                        <div className='row justify-content-center g-2 mt-5 '>
                            <ReactToPrint
                                trigger={() =>
                                    <Button variant="" className='but-all w-100' onClick={() => ondrawer('report', true)}>
                                        طباعة التقرير
                                    </Button>
                                }
                                content={() => targetRef.current}
                            />

                        </div>
                    }
                </Offcanvas.Body>
            </Offcanvas>
            <ReportPop
                open={pop.report}
                title={title}
                onClose={() => ondrawer('report', false)}
                onOK={onButtonReport}
            />
        </>
    )
}
