export const customDataSurvey = (data) => {
    const Data = data.map(item => ({
        "الإسم الثلاثي": item.name,
        "الرحلة": item.trips,
        "المحافظة": item.city,
        "الجنس": item.sex,
        "العمر": item.age,
        "التحصيل الدراسي": item.education,
        "رقم الهاتف": item.phone,
        "رابط الاستمارة": item.survey_link,
        "الرحلات السابقة": item.previous_trips ? "نعم يوجد رحلة" : "لا يوجد رحلة",
        "الأطفال": item.has_children ? "نعم يوجد اطفال" : "لا يوجد اطفال",
        "عدد الأطفال": item.children_count ? item.children_count : "لا يوجد اطفال",
        "اعمار الأطفال": `${item.children_ages}`,
        "حالة الرحلة": item.is_active ? 'تم الحضور' : "لم يتم الحضور",
    })
    )
    return Data
}
