import React, { useEffect, useState } from 'react'
import { Card, Nav, ProgressBar, Row, Table } from 'react-bootstrap'
import CardHeader from '../components/cards/CardHeader';
import Cards from '../components/cards/Card';
import axiosInstance from '../api/axiosInstance';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function Home() {
  const [loading, setLoading] = useState(true)
  const [countEmirli, setCountEmirli] = useState(0)
  const [countBaiji, setCountBaiji] = useState(0)
  const [countEmirliActive, setCountEmirliActive] = useState(0)
  const [countBaijiActive, setCountBaijiActive] = useState(0)
  const [listEmirli, setListEmirli] = useState([])
  const [listBaiji, setListBaiji] = useState([])
  useEffect(() => {
    async function getData() {
      await axiosInstance.get('/m3raaj/api/v1/survey/?type=0')
        .then(res => {
          setCountEmirli(res.data.count)
          setListEmirli(res.data.results)
          if (!res.ok) { setLoading(false) }
        })
      await axiosInstance.get('/m3raaj/api/v1/survey/?type=1')
        .then(res => {
          setCountBaiji(res.data.count)
          setListBaiji(res.data.results)
          if (!res.ok) { setLoading(false) }
        })
      await axiosInstance.get('/m3raaj/api/v1/survey/?type=0&is_active=true')
        .then(res => {
          setCountEmirliActive(res.data.count)
          if (!res.ok) { setLoading(false) }
        })
      await axiosInstance.get('/m3raaj/api/v1/survey/?type=1&is_active=true')
        .then(res => {
          setCountBaijiActive(res.data.count)
          if (!res.ok) { setLoading(false) }
        })
    }
    getData()
  }, []);
  return (
    <div className='mt-5'>
      <div class="container-fluid">
        <div class="row justify-content-center align-items-center g-2">
          <div class="col-lg-12">
            <div class="container-fluid">
              <div class="row justify-content-center align-items-center g-2">
                <div class="col-lg-3">
                  <CardHeader
                    icon='fas fa-car-rear'
                    title='رحلة امرلي'
                    count={countEmirli}
                    text='أجمالي العدد الكلي للحجوزات في رحلة امرلي'
                  />
                </div>
                <div class="col-lg-3">
                  <CardHeader
                    icon='fas fa-users'
                    title='الحضور في رحلة  أمرلي'
                    count={countEmirliActive}
                    value=""
                    text='أجمالي العدد الكلي للحضور في رحلة امرلي'
                  />
                </div>
                <div class="col-lg-3">
                  <CardHeader
                    icon='fas fa-car-rear'
                    title='رحلة بيجي'
                    count={countBaiji}
                    text='أجمالي العدد الكلي للحجوزات في رحلة بيجي'
                  />
                </div>

                <div class="col-lg-3">
                  <CardHeader
                    icon='fas fa-users'
                    title='الحضور في رحلة  بيجي'
                    count={countBaijiActive}
                    value=""
                    text='أجمالي العدد الكلي للحضور في رحلة بيجي'
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-4">
            <div class="container-fluid">
              <div class="row justify-content-center align-items-center g-2">
                <div class="col">
                  <Cards title='رحلة أمرلي' hasButton path='/emirli/list/' >
                    <div className='table-responsive'>
                      <Table className="align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th>ت</th>
                            <th scope="col">الأسم</th>
                            <th scope="col">الرحلة</th>
                            <th scope="col">المحافظة</th>
                            <th scope="col">الجنس</th>
                            <th scope="col">العمر</th>
                            <th scope="col">التحصيل الدراسي</th>
                            <th scope="col">رقم الهاتف</th>
                            <th scope="col">رابط الاستمارة</th>
                            <th scope="col"> الرحلات السابقة</th>
                            <th scope="col">الأطفال</th>
                            <th scope="col">عدد الأطفال</th>
                            <th scope="col">تاريخ التقديم</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listEmirli.map((item, index) => {
                            return (
                              <tr key={item.id}>
                                <td>{index + 1}</td>
                                <th scope="row">
                                  <Link to={`/emirli/list/item/id/${item.id}/name/${item.name}`}>
                                    {item.name}
                                  </Link>
                                </th>
                                <td>{item.trips}</td>
                                <td>{item.city}</td>
                                <td>{item.sex}</td>
                                <td>{item.age}</td>
                                <td>{item.education}</td>
                                <td>{item.phone}</td>
                                <td>{item.survey_link}</td>
                                <td>{item.previous_trips ? "نعم يوجد رحلة" : "لا يوجد رحلة"}</td>
                                <td>{item.has_children ? "نعم يوجد اطفال" : "لا يوجد اطفال"}</td>
                                <td>{item.children_count ? item.children_count : "لا يوجد اطفال"}</td>
                                <td dir='ltr'>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                              </tr>
                            )
                          })}

                        </tbody>
                      </Table>
                    </div>
                  </Cards>
                </div>
                <div class="col">
                  <Cards title='رحلة بيجي' hasButton path='/baiji/list/'>
                    <div className='table-responsive'>
                      <Table className="align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th>ت</th>
                            <th scope="col">الأسم</th>
                            <th scope="col">الرحلة</th>
                            <th scope="col">المحافظة</th>
                            <th scope="col">الجنس</th>
                            <th scope="col">العمر</th>
                            <th scope="col">التحصيل الدراسي</th>
                            <th scope="col">رقم الهاتف</th>
                            <th scope="col">رابط الاستمارة</th>
                            <th scope="col"> الرحلات السابقة</th>
                            <th scope="col">الأطفال</th>
                            <th scope="col">عدد الأطفال</th>
                            <th scope="col">تاريخ التقديم</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listBaiji.map((item, index) => {
                            return (
                              <tr key={item.id}>
                                <td>{index + 1}</td>
                                <th scope="row">
                                  <Link to={`/baiji/list/item/id/${item.id}/name/${item.name}`}>
                                    {item.name}
                                  </Link>
                                </th>
                                <td>{item.trips}</td>
                                <td>{item.city}</td>
                                <td>{item.sex}</td>
                                <td>{item.age}</td>
                                <td>{item.education}</td>
                                <td>{item.phone}</td>
                                <td>{item.survey_link}</td>
                                <td>{item.previous_trips ? "نعم يوجد رحلة" : "لا يوجد رحلة"}</td>
                                <td>{item.has_children ? "نعم يوجد اطفال" : "لا يوجد اطفال"}</td>
                                <td>{item.children_count ? item.children_count : "لا يوجد اطفال"}</td>
                                <td dir='ltr'>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Cards>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
