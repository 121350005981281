export const treeData = [
    {
        value: 0,
        title: 'مسؤول نظام معراج',
    },
    {
        value: 1,
        title: 'مدير معراج',
    },
    {
        value: 2,
        title: 'مشرف استمارات معراج',
    },
];



export const userType = (type) => {
    switch (type) {
        case 0: return 'مسؤول نظام معراج'
        case 1: return 'مدير معراج'
        case 2: return 'مشرف استمارات معراج'

    }
};
export const userGroup = (type) => {
    switch (type) {
        case 0: return 'مدير'
        case 1: return 'محرر'
        case 2: return 'مدير مستخدمين'
    }
};