import React from 'react'
import { Bar } from 'react-chartjs-2';
import Chart from "chart.js";
import { 
    chartOptions,
    parseOptions,
    chartExample2
 } from './chartOptions';

export default function BarChart({ labels, data }) {
    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }
    return (
        <div className='chart p-2'>
            <Bar
                data={chartExample2.data(labels, data)}
                options={{ maintainAspectRatio: false }}
                width={150}
                height={50}
            />
        </div>

    )
}
